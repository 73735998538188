<template>
  <div id="navi" class="is-flex is-vertical f_left">
    <div class="navi-title fix is-flex is-liner f_left f_vcenter" style="flex-basis:60px;min-height:60px;">
      <h1 class="grow site-title has-pad-l20">GK Market Analyzer</h1>
    </div>

    <button class="navi-box fix" style="flex-basis:60px;min-height:60px;"
            v-on:click="jumpPage('/')"
            v-bind:class="isActivePage('top')">
      <fa icon="chart-column" class="fa-xl"/>
    </button>
    <button class="navi-box fix" style="flex-basis:60px;min-height:60px;"
            v-on:click="jumpPage('/ranking')"
            v-bind:class="isActivePage('ranking')">
      <fa icon="ranking-star" class="fa-xl"/>
    </button>
    <button class="navi-box" style="flex-basis:60px;min-height:60px;"
            v-on:click="jumpPage('/item')"
            v-bind:class="isActivePage('item')">
      <fa icon="shield-halved" class="fa-xl"/>
    </button>
    <button class="navi-box" style="flex-basis:60px;min-height:60px;"
            v-on:click="jumpPage('/consumption')"
            v-bind:class="isActivePage('consumption')">
      <fa icon="flask" class="fa-xl"/>
    </button>

    <button class="navi-box" style="flex-basis:60px;min-height:60px;"
            v-on:click="jumpPage('/winning')"
            v-bind:class="isActivePage('winning')">
      <fa icon="face-grin-hearts" class="fa-xl"/>
    </button>

    <button class="navi-box" style="flex-basis:60px;min-height:60px;"
            v-on:click="jumpPage('/balance')"
            v-bind:class="isActivePage('balance')"
            v-if="canUseExtMenu===true">
      <fa icon="scale-balanced" class="fa-xl"/>
    </button>
    <button class="navi-box" style="flex-basis:60px;min-height:60px;"
            v-on:click="jumpPage('/comment')"
            v-bind:class="isActivePage('comment')"
            v-if="canUseExtMenu===true">
      <fa :icon="['far', 'comments']" class="fa-xl"/>
    </button>
    <button class="navi-box" style="flex-basis:60px;min-height:60px;"
            v-on:click="jumpPage('/help')"
            v-bind:class="isActivePage('help')">
      <fa :icon="['far', 'circle-question']" class="fa-xl"/>
    </button>

    <div class="spacer grow is-flex is-liner f_left f_vcenter" v-on:click="setExtSecond()"></div>

  </div>
</template>

<script>

//import {commonFunc} from '@/models/commonFunc'
//import * as _ from "lodash";

import {Conf} from "@/models/conf";

export default {
  props: {
    config: new Conf(),
    ts: String
  },
  data() {
    return {
      jumpPath: '',
      setExtFirstCnt: 0,
      setExtSecondCnt: 0
    }
  },
  computed: {
    canUseExtMenu: function () {
      return this.config.auth > 0;
    },
    getPath() {
      return this.$route.path;
    }
  },
  created() {
  },
  mounted() {
    //document.addEventListener('keydown', this.keydownEvent, false);
  },
  watch: {},
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/
    jumpPage: function (path) {
      this.$router.push(path);
      this.setExtFirst();
    },
    /*
    keydownEvent: function (event) {
      if (event.ctrlKey === true) {
        if (event.code === 'F1') {
          this.setAuth(1);
        }
      }
    },
    */
    setExtFirst: function () {
      if (this.setExtSecondCnt === 2) {
        this.setAuth(1);
        this.setExtFirstCnt = 0;
        this.setExtSecondCnt = 0;
      }else{
        this.setExtFirstCnt++;
      }
    },
    setExtSecond: function () {
      if (this.setExtFirstCnt === 3) {
        this.setExtSecondCnt++;
      } else {
        this.setExtFirstCnt = 0;
        this.setExtSecondCnt = 0;
      }
    },

    /*************************************************
     * 算出処理
     *************************************************/

    /*************************************************
     * 動的クラス設定
     *************************************************/
    isActivePage: function (pageName) {
      let cname = {'is-active': false};

      if (this.$route.name === pageName) {
        cname['is-active'] = true;
      }
      return cname;
    },


    /*************************************************
     * コンポーネント連携
     *************************************************/

    setAuth: function (auth) {
      this.$emit('setAuth', auth);
    }

  },

};
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>