<template>
  <div id="contents" class="content is-flex-auto-vertical is-liner">

    <div id="side" class="fix" style="flex-basis:60px;min-width:60px;">
      <navbar
          :config="config"
          @setAuth="setAuth"></navbar>
    </div>

    <div id="main-content" class="grow is-flex is-vertical">

      <div id="pageMenu" class="fix is-flex-auto-vertical is-liner f_left">
        <div class="navi-title is-flex is-liner f_left f_vcenter">
          <h1 class="grow site-title has-pad-l20">GK Market Analyzer</h1>
        </div>

        <div class="spacer grow is-flex is-liner f_left f_vcenter"></div>
      </div>


      <div id="content" class="is-flex is-vertical">

        <h2>当選番号確認（2024年7月15日22:00～の抽選会用）</h2>

        <div class="winning-checker">

          <div class="is-flex is-liner">

            <div class="fix is-flex is-vertical has-pad-20" style="flex-basis:50%; min-width:50%;">

              <div class="is-flex is-liner winning-line">
                <div class="fix" style="flex-basis:180px;">ウォレットアドレス</div>
                <div class="grow">
                  <input type="text" class="input" v-model="address">
                </div>
              </div>

              <div class="is-flex is-liner winning-line">
                <div class="fix" style="flex-basis:150px;">&nbsp;</div>
                <div class="grow is-flex is-liner f_right">
                  <button class="button is-info has-margin-r10"
                          v-bind:disabled="canNumber===false"
                          v-on:click="checkNumbers()">抽選番号確認
                  </button>
                  <button class="button is-info"
                          v-bind:disabled="canWinning===false"
                          v-on:click="checkWinning()">当選結果確認
                  </button>

                </div>
              </div>

            </div>

            <div class="fix is-flex is-vertical has-pad-20" style="flex-basis:50%; min-width:50%;">

              <div class="is-flex is-liner winning-line">
                <div class="grow is-flex is-liner">
                  <div class="fix" style="flex-basis:100px;">抽選番号</div>
                  <div class="grow has-pad-r10">
                    <div class="tag resultWinning is-flex f_center">
                      <span v-text="numbers"></span>
                    </div>
                  </div>
                  <div class="fix has-pad-l10" style="flex-basis:100px;">抽選本数</div>
                  <div class="fix" style="flex-basis:100px;min-width:100px;">
                    <div class="tag resultWinning is-flex f_center">
                      <span v-text="cnt"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="winning-line">
                <textarea class="textarea checkResult" v-model="resultData" readonly></textarea>

              </div>


            </div>


          </div>


        </div>


      </div>

    </div>

  </div>
</template>

<script>
import {commonFunc} from '@/models/commonFunc'
import navbar from "./common/NaviHeader.vue";
import axios from "axios";

import {Conf} from "@/models/conf";

export default {
  components: {
    'navbar': navbar,
  },
  name: 'help',
  props: {
    config: new Conf()
  },
  data() {
    return {
      address: '',
      jsonData: {},
      start: 0,
      end: 0,
      numbers: '----',
      cnt: 0,
      resultData: '',
      apiBase: 'https://sheets.googleapis.com/v4/spreadsheets/##SHEETID##/values/##SHEETNAME##?key=##APIKEY##',
      apiKey: 'AIzaSyAqVL4EWzb5Vy_x_C3xQn1PhlO4fhJARdU',
      //テスト用
      /*
      sheets: [
        {id: '1cXeoTr37IgmmUYxikLr5CAyPQoqvepWNtpEL-kbk0MU', name: 'Your Chance#4'},
        {id: '16wjblfQp3Ds8T9dv3DwWFICqthY2n-kXuAuKvArmVyg', name: 'Your Chance'},
        {id: '16wjblfQp3Ds8T9dv3DwWFICqthY2n-kXuAuKvArmVyg', name: '当選番号出力'}
      ],
      target0: 'https://docs.google.com/spreadsheets/d/1cXeoTr37IgmmUYxikLr5CAyPQoqvepWNtpEL-kbk0MU/edit?hl=ja#gid=476010087',
      target1: 'https://docs.google.com/spreadsheets/d/16wjblfQp3Ds8T9dv3DwWFICqthY2n-kXuAuKvArmVyg/edit#gid=2073966637',
      target2: 'https://docs.google.com/spreadsheets/d/16wjblfQp3Ds8T9dv3DwWFICqthY2n-kXuAuKvArmVyg/edit#gid=1305102710',
      */
      //GensoKishi公開先

      sheets: [
        {id: '1nhL8gTI5d8Ltli4pBZYfdYc_8U3wG99LbeDWhc5DZGM', name: 'Your Chance#'},
        {id: '1A3IMzMAeNzFmec_JxM8NRQGFUNLwVlbqK5rXRWcHY9c', name: 'Your Chance'}
      ],
      /*
      target0: 'https://docs.google.com/spreadsheets/d/1IGgCkcG-e0BHNMAlcVFw2LFvn9Flsj_P4OCMa_9JE8E/edit#gid=352642544',
      target1: 'https://docs.google.com/spreadsheets/d/1k9mtLln3U7QK93PzCd2dopqdcrrsGvuPVMj6RYNlTso/edit#gid=399341418',
      target2: 'https://docs.google.com/spreadsheets/d/1A3IMzMAeNzFmec_JxM8NRQGFUNLwVlbqK5rXRWcHY9c/edit#gid=376406756',
      */
    }
  },
  computed: {
    canNumber: function () {
      return (this.address !== '')
    },
    canWinning: function () {
      return (this.start > 0 && this.end > 0)
    },
  },
  watch: {
    'address': function (newVal, oldVal) {
      this.start = 0;
      this.end = 0;
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/
    checkNumbers: function () {
      let searchWord = this.makeShortAddress(this.address);
      if (searchWord !== '') {
        let apiUrl = this.apiBase.replace('##APIKEY##', this.apiKey);
        apiUrl = apiUrl.replace('##SHEETID##', this.sheets[0].id);
        apiUrl = apiUrl.replace('##SHEETNAME##', encodeURIComponent(this.sheets[0].name));
        //console.log('apiUrl',apiUrl);
        let self = this;
        this.callApi(apiUrl)
            .then(function () {
              if (self.jsonData && self.jsonData.values) {
                let lines = self.jsonData.values.find(function (rec) {
                  if (rec[2]) {
                    return rec[2] === searchWord;
                  }
                });
                if (lines && lines[3] && lines[5]) {
                  self.start = parseInt(lines[3], 10);
                  self.end = parseInt(lines[5], 10);
                  if (self.start > 0 && self.end > 0) {
                    self.numbers = self.start + ' ～ ' + self.end;
                    self.cnt = self.end - self.start + 1;
                  } else {
                    self.resetResults();
                  }
                } else {
                  self.resetResults();
                }
              } else {
                self.resetResults();
              }

            })

      }
    },
    checkWinning: function () {
      this.resultData = '';
      let searchWord = this.makeShortAddress(this.address);
      if (searchWord !== '') {
        let apiUrl = this.apiBase.replace('##APIKEY##', this.apiKey);
        apiUrl = apiUrl.replace('##SHEETID##', this.sheets[1].id);
        apiUrl = apiUrl.replace('##SHEETNAME##', encodeURIComponent(this.sheets[1].name));

        let self = this;
        this.callApi(apiUrl)
            .then(function () {
              if (self.jsonData && self.jsonData.values) {
                let numbers = [];
                for (let i = self.start; i <= self.end; i++) {
                  numbers.push(i);
                }
                let lines = self.jsonData.values.filter(function (rec) {
                  if (rec[1] && rec[2]) {
                    return searchWord === rec[1] && numbers.includes(parseInt(rec[2], 10));
                  }
                });
                if (lines.length > 0) {
                  let tmpResultData = [];
                  for (let i = 0; i < lines.length; i++) {
                    let tmp = lines[i];
                    if (tmp[2] && tmp[3]) {
                      tmpResultData.push(tmp[3] + ' [' + tmp[2] + ']');
                    }
                  }
                  tmpResultData.sort();
                  self.resultData = tmpResultData.join("\n");
                }

              }
            });
      }

    },


    /*************************************************
     * 処理関数
     *************************************************/
    resetResults: function () {
      this.start = 0;
      this.end = 0;
      this.cnt = 0;
      this.numbers = '該当なし';
    },
    makeShortAddress: function (address) {
      if (!address) {
        return '';
      }
      let padding = '････';
      return address.slice(0, 6) + padding + address.slice(-6);
    },
    callApi: function (url) {
      let self = this;
      return axios.get(url)
          .then(function (response) {
            if (response.data) {
              self.jsonData = response.data;
            }
            return response.data;
          })
          .catch(error => {
            return {status: '9', messages: '通信エラー'};
          });
    },

    /*************************************************
     * 初期処理
     *************************************************/

    /*************************************************
     * コンポーネント連携
     *************************************************/
    setAuth: function (auth) {
      this.$emit('setAuth', auth);
    }


  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>
