import * as moment from 'moment';
import * as Model from './base';
import {MOMENT_FORMAT_PLAIN_SHORT} from "./base";

export class Consumption extends Model.modelItemBase {
    constructor(params = {}) {
        super();

        this.image_mode = 'min';

        this.consumption_id = 0;
        this.title = '';
        this.title_en = '';
        this.img_logo = '';
        this.item_type = 0;
        this.rarity = 0;
        this.maximum_number = 0;
        this.description = '';
    }

    static fromJson(params, detail) {
        let out = (detail) ? detail : new Consumption();

        out.consumption_id = parseInt(params.consumption_id, 10);
        out.title = params.title;
        out.title_en = params.title_en;
        if (out.image_mode === 'min') {
            out.img_logo = './consumption_images_min/' + params.img_logo.replace(/png|gif/g, 'jpg');
        } else {
            out.img_logo = './consumption_images/' + params.img_logo;
        }
        out.item_type = parseInt(params.item_type, 10);
        out.rarity = parseInt(params.rarity, 10);
        out.maximum_number = parseInt(params.maximum_number, 10);
        out.description = params.description;

        return out;
    }

}

export class Consumptions extends Model.modelBase {
    constructor(Ranks = []) {
        super(Ranks);
    }

    static fromJson(params = {}) {
        let items = [];
        params.forEach((item) => {
            items.push(Consumption.fromJson(item));
        });

        let out = new Consumptions(items);
        return out;
    }

}
