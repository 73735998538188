<template>
  <div id="contents" class="content is-flex-auto-vertical is-liner">

    <div id="side" class="fix" style="flex-basis:60px;min-width:60px;">
      <navbar
          :config="config"
          @setAuth="setAuth"></navbar>
    </div>

    <div id="main-content" class="grow is-flex is-vertical">

      <div id="pageMenu" class="fix is-flex-auto-vertical is-liner f_left">

        <div class="navi-title is-flex is-liner f_left f_vcenter">
          <h1 class="grow site-title has-pad-l20">GK Market Analyzer</h1>
        </div>

        <button class="navi-box"
                v-on:click="setMode('base')"
                v-bind:class="isActiveMode('base')">ベース装備
        </button>
        <button class="navi-box"
                v-on:click="setMode('cosplay')"
                v-bind:class="isActiveMode('cosplay')">オシャレ装備
        </button>
        <div class="spacer grow is-flex is-liner f_left f_vcenter"></div>
      </div>

      <div id="content" class="is-flex is-vertical">


        <h2 id="item-list-title-header" v-text="modeTitle"></h2>

        <div class="item-list-with-thumbnail is-flex is-vertical">

          <div id="item-list-header" class="item-list-header" v-on:scroll="scrollItemList($event, 'header')">

            <div class="status-parm-header is-flex is-liner">

              <div class="status-parm-header-rap is-flex is-liner fix" style="flex-basis:470px; min-width:470px;">
                <div class="grow title" v-on:click="sortColumn('default')">&nbsp;</div>
              </div>

              <div class="status-parm-header-rap grow is-flex is-liner f_right">
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.hp')"
                     v-on:click="sortColumn('status.hp')">
                  HP
                </div>
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.mp')"
                     v-on:click="sortColumn('status.mp')">
                  MP
                </div>
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.attack')"
                     v-on:click="sortColumn('status.attack')">
                  物攻
                </div>
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.defense')"
                     v-on:click="sortColumn('status.defense')">
                  防御
                </div>
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.magic_attack')"
                     v-on:click="sortColumn('status.magic_attack')">
                  魔攻
                </div>
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.atk_spd')"
                     v-on:click="sortColumn('status.atk_spd')">
                  攻速
                </div>
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.str')"
                     v-on:click="sortColumn('status.str')">
                  腕力
                </div>
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.vit')"
                     v-on:click="sortColumn('status.vit')">
                  体力
                </div>
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.agi')"
                     v-on:click="sortColumn('status.agi')">
                  速さ
                </div>
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.inte')"
                     v-on:click="sortColumn('status.inte')">
                  知力
                </div>
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.dex')"
                     v-on:click="sortColumn('status.dex')">
                  器用
                </div>
                <div class="fix title" style="flex-basis:8.3%;"
                     v-bind:class="setSortedClass('status.mnd')"
                     v-on:click="sortColumn('status.mnd')">
                  精神
                </div>
              </div>
              <div class="status-parm-header-rap">
                <div class="fix" style="flex-basis:18px;min-width:18px">&nbsp;</div>
              </div>
            </div>

          </div>

          <div id="item-list-body" class="grow item-list-body" v-on:scroll="scrollItemList($event, 'body')">

            <template v-for="item in items[mode].items" :key="item.item_id">

              <div class="card item-card" v-bind:class="setRarityBgClass(item.rarity)">

                <div class="card-content">

                  <div class="is-flex is-liner">

                    <div class="is-flex is-liner fix" style="flex-basis:470px;min-width:470px;">

                      <div class="card-image fix"
                           style="flex-basis:80px;min-width:80px;height:78px;">
                        <img class="img-frame-mini has-link" v-bind:src="item.img_logo" alt="item.title_en"
                             loading="lazy"
                             v-on:click="showDetailPage(item.item_id)">
                      </div>

                      <div class="content grow is-flex is-vertical">

                        <div class="line">
                          <p class="name_en" v-text="getTitleEnLine(item)"></p>
                          <div>
                            <span v-text="'['+getPartsName(item.parts_type)+'] '"></span>
                            <span class="title name" v-text="item.title"></span>
                          </div>
                          <div class="jobs-span-list is-flex is-liner">
                            <span class="tag" v-bind:class="setJobDisableClass(1,item.jobs)">ﾌｧｲ</span>
                            <span class="tag" v-bind:class="setJobDisableClass(2,item.jobs)">ｼｰﾌ</span>
                            <span class="tag" v-bind:class="setJobDisableClass(4,item.jobs)">ｸﾚ</span>
                            <span class="tag" v-bind:class="setJobDisableClass(8,item.jobs)">ｳｨｻﾞ</span>
                            <span class="tag" v-bind:class="setJobDisableClass(16,item.jobs)">ｶﾞﾃﾞ</span>
                            <span class="tag" v-bind:class="setJobDisableClass(32,item.jobs)">ﾏｼﾞﾅｲ</span>
                            <span class="tag" v-bind:class="setJobDisableClass(64,item.jobs)">ｱｻｼﾝ</span>
                          </div>
                          <div class="jobs-span-list is-flex is-liner">
                            <span class="tag" v-bind:class="setJobDisableClass(128,item.jobs)">ﾋﾞｼｮ</span>
                            <span class="tag" v-bind:class="setJobDisableClass(256,item.jobs)">ｳｫﾛ</span>
                            <span class="tag" v-bind:class="setJobDisableClass(512,item.jobs)">ﾓﾝｸ</span>
                            <span class="tag" v-bind:class="setJobDisableClass(1024,item.jobs)">ﾗﾝｻ</span>
                            <span class="tag" v-bind:class="setJobDisableClass(2048,item.jobs)">ﾊﾞｽﾀ</span>
                            <span class="tag" v-bind:class="setJobDisableClass(4096,item.jobs)">ｾｲﾊﾞ</span>
                          </div>
                        </div>


                      </div>

                    </div>

                    <div class="content grow is-flex is-vertical">

                      <div class="status-parm-rap grow is-flex is-liner f_right">
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.hp"></span>
                        </div>
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.mp"></span>
                        </div>
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.attack"></span>
                        </div>
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.defense"></span>
                        </div>
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.magic_attack"></span>
                        </div>
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.atk_spd"></span>
                        </div>
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.str"></span>
                        </div>
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.vit"></span>
                        </div>
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.agi"></span>
                        </div>
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.inte"></span>
                        </div>
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.dex"></span>
                        </div>
                        <div class="fix title is-flex is-liner f_center f_vcenter"
                             style="flex-basis:8.3%;">
                          <span v-text="item.status.mnd"></span>
                        </div>

                      </div>

                    </div>


                  </div>


                </div>
              </div>


            </template>

          </div>


        </div>

      </div>

      <div id="searchBox" class="fix searchBox" v-bind:class="setSearchBoxClass()">
        <div class="searchBoxRap is-flex is-vertical">
          <div class="baseSearchBox is-flex is-liner">

            <div class="searchLine is-flex is-liner">
              <div class="fix dummySpace" style="flex-basis:60px;min-width:60px;"></div>
              <div class="grow baseSearchLine is-flex is-liner f_vcenter has-flex-rap">
                <div class="fix searchLabel" style="flex-basis:60px;">名称</div>
                <div class="fix searchColumn" style="flex-basis:300px;min-width:300px;">
                  <input type="text" class="input" v-model="search.items.title">
                </div>

                <div class="fix searchColumn has-pad-l10" style="flex-basis:100px;min-width:100px;">
                  <button class="button"
                          v-on:click="initSearch()">クリア
                  </button>
                </div>

                <div class="fix searchColumn is-flex is-liner" style="flex-basis:150px;min-width:150px;">

                  <div class="buttons has-addons selectGroupButtons">
                    <button class="button"
                            v-bind:class="setSelectedRarityButtonClass('normal')"
                            v-on:click="search.items.rarity.normal=!search.items.rarity.normal">Ｎ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedRarityButtonClass('rare')"
                            v-on:click="search.items.rarity.rare=!search.items.rarity.rare">Ｒ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedRarityButtonClass('superRare')"
                            v-on:click="search.items.rarity.superRare=!search.items.rarity.superRare">SR
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedRarityButtonClass('legend')"
                            v-on:click="search.items.rarity.legend=!search.items.rarity.legend">LR
                    </button>
                  </div>

                </div>

                <div class="fix searchColumn" style="flex-basis:370px;min-width:370px;">

                  <div class="buttons has-addons selectGroupButtons">
                    <button class="button"
                            v-bind:class="setSelectedPartsButtonClass('right')"
                            v-on:click="search.items.parts.right=!search.items.parts.right">右手
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedPartsButtonClass('left')"
                            v-on:click="search.items.parts.left=!search.items.parts.left">左手
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedPartsButtonClass('head')"
                            v-on:click="search.items.parts.head=!search.items.parts.head">頭
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedPartsButtonClass('body')"
                            v-on:click="search.items.parts.body=!search.items.parts.body">体
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedPartsButtonClass('leg')"
                            v-on:click="search.items.parts.leg=!search.items.parts.leg">足
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedPartsButtonClass('shoulder')"
                            v-on:click="search.items.parts.shoulder=!search.items.parts.shoulder">肩
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedPartsButtonClass('back')"
                            v-on:click="search.items.parts.back=!search.items.parts.back">背
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedPartsButtonClass('ring')"
                            v-on:click="search.items.parts.ring=!search.items.parts.ring">指輪
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedPartsButtonClass('amano')"
                            v-on:click="search.items.parts.amano=!search.items.parts.amano">SET
                    </button>
                  </div>

                </div>

                <!--
                <div class="fix searchColumn" style="flex-basis:160px;min-width:160px;">

                  <div class="buttons has-addons selectGroupButtons">
                    <button class="button"
                            v-bind:class="setShowDtlClass('on')"
                            v-on:click="changeShowDtl('on')">詳細ON
                    </button>
                    <button class="button"
                            v-bind:class="setShowDtlClass('off')"
                            v-on:click="changeShowDtl('off')">詳細OFF
                    </button>
                  </div>
                </div>
                -->


              </div>
            </div>

            <div class="toggleBox">
              <button class="toggleButton" v-on:click="toggleSeachBox()">
                <fa icon="fa-plus" class="fa-xl"
                    v-if="search.boxStatus===false"/>
                <fa icon="fa-minus" class="fa-xl"
                    v-if="search.boxStatus===true"/>
              </button>
            </div>
          </div>

          <div class="extSearchBox is-flex is-vertical" v-if="search.boxStatus===true">

            <div class="searchLine is-flex is-liner">
              <div class="fix dummySpace" style="flex-basis:60px;min-width:60px;"></div>
              <div class="grow baseSearchLine is-flex is-liner f_vcenter has-flex-rap">


                <div class="fix searchColumn" style="flex-basis:330px;min-width:330px;">

                  <div class="buttons has-addons selectGroupButtons">
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('fighter')"
                            v-on:click="setInitAndEnableSearch('fighter')">ﾌｧｲ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('thief')"
                            v-on:click="setInitAndEnableSearch('thief')">ｼｰﾌ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('cleric')"
                            v-on:click="setInitAndEnableSearch('cleric')">ｸﾚ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('wizard')"
                            v-on:click="setInitAndEnableSearch('wizard')">ｳｨｻﾞ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('guardian')"
                            v-on:click="setInitAndEnableSearch('guardian')">ｶﾞﾃﾞ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('magiknight')"
                            v-on:click="setInitAndEnableSearch('magiknight')">ﾏｼﾞﾅｲ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('assassin')"
                            v-on:click="setInitAndEnableSearch('assassin')">ｱｻｼﾝ
                    </button>

                  </div>

                </div>

                <div class="fix searchColumn" style="flex-basis:330px;min-width:330px;">

                  <div class="buttons has-addons selectGroupButtons">

                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('bishop')"
                            v-on:click="setInitAndEnableSearch('bishop')">ﾋﾞｼｮ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('warlock')"
                            v-on:click="setInitAndEnableSearch('warlock')">ｳｫﾛ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('monk')"
                            v-on:click="setInitAndEnableSearch('monk')">ﾓﾝｸ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('lancer')"
                            v-on:click="setInitAndEnableSearch('lancer')">ﾗﾝｻ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('buster')"
                            v-on:click="setInitAndEnableSearch('buster')">ﾊﾞｽﾀ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedJobsButtonClass('saber')"
                            v-on:click="setInitAndEnableSearch('saber')">ｾｲﾊﾞ
                    </button>
                  </div>

                </div>



              </div>

            </div>


          </div>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import {commonFunc} from '@/models/commonFunc'
import navbar from "./common/NaviHeader.vue";
import axios from "axios";

import {Conf} from "@/models/conf";
import {Items, Item} from "@/models/item";

import * as _ from "lodash";

export default {
  components: {
    'navbar': navbar,
  },
  name: 'item',
  props: {
    config: new Conf()
  },
  data() {
    return {
      mode: 'base', //base, cosplay
      elemSize: {
        base: {
          widthApp: 0,
          heightApp: 0,
        },
        listBody: {
          width: 0,
          height: 650,
        }
      },
      jsonData: {
        base: new Items(),
        cosplay: new Items()
      },
      items: {
        base: new Items(),
        cosplay: new Items()
      },
      search: {
        boxStatus: false,
        items: {
          title: '',
          rarity: {
            normal: false,
            rare: false,
            superRare: false,
            legend: false
          },
          parts: {
            right: false,
            left: false,
            head: false,
            body: false,
            leg: false,
            shoulder: false,
            back: false,
            ring: false,
            amano: false
          },
          jobs: {
            fighter: false,
            thief: false,
            cleric: false,
            wizard: false,
            guardian: false,
            magiknight: false,
            assassin: false,
            bishop: false,
            warlock: false,
            monk: false,
            lancer:false,
            buster:false,
            saber:false
          }
        },
        itemsOrigin: {}
      },
      sort: {
        col: 'default',
        order: 'asc'
      },
      scroll: {
        x: 0
      },
      status: {
        showDtl: true,
        resize: false,
      }
    }
  },
  computed: {
    // 追加可否判定
    modeTitle: function () {
      return (this.mode === 'base') ? 'ベース装備一覧' : (this.mode === 'cosplay') ? 'オシャレ装備一覧' : '';
    },
    rarity_value: function () {
      let val = 0;
      val += (this.search.items.rarity.normal === true) ? 1 : 0;
      val += (this.search.items.rarity.rare === true) ? 2 : 0;
      val += (this.search.items.rarity.superRare === true) ? 4 : 0;
      val += (this.search.items.rarity.legend === true) ? 8 : 0;
      return val;
    },
    parts_value: function () {
      let val = 0;
      val += (this.search.items.parts.right === true) ? 1 : 0;
      val += (this.search.items.parts.left === true) ? 2 : 0;
      val += (this.search.items.parts.head === true) ? 4 : 0;
      val += (this.search.items.parts.body === true) ? 8 : 0;
      val += (this.search.items.parts.leg === true) ? 16 : 0;
      val += (this.search.items.parts.shoulder === true) ? 32 : 0;
      val += (this.search.items.parts.back === true) ? 64 : 0;
      val += (this.search.items.parts.amano === true) ? 128 : 0;
      val += (this.search.items.parts.ring === true) ? 256 : 0;
      return val;
    },
    jobs_value: function () {
      let val = 0;
      val += (this.search.items.jobs.fighter === true) ? 1 : 0;
      val += (this.search.items.jobs.thief === true) ? 2 : 0;
      val += (this.search.items.jobs.cleric === true) ? 4 : 0;
      val += (this.search.items.jobs.wizard === true) ? 8 : 0;
      val += (this.search.items.jobs.guardian === true) ? 16 : 0;
      val += (this.search.items.jobs.magiknight === true) ? 32 : 0;
      val += (this.search.items.jobs.assassin === true) ? 64 : 0;
      val += (this.search.items.jobs.bishop === true) ? 128 : 0;
      val += (this.search.items.jobs.warlock === true) ? 256 : 0;
      val += (this.search.items.jobs.monk === true) ? 512 : 0;
      val += (this.search.items.jobs.lancer === true) ? 1024 : 0;
      val += (this.search.items.jobs.buster === true) ? 2048 : 0;
      val += (this.search.items.jobs.saber === true) ? 4096 : 0;
      return val;
    },
  },
  created() {
  },
  mounted() {
    this.search.itemsOrigin = _.cloneDeep(this.search.items);
    this.firstLoad()
        .then(this.initData);
    this.calculateWindowWidth();
    this.setResizeFlg(true);
    window.addEventListener('resize', this.calculateWindowWidth);
  },
  watch: {
    'rarity_value': function (to) {
      this.initData();
    },
    'parts_value': function (to) {
      this.initData();
    },
    'jobs_value': function (to) {
      this.initData();
    },
    'search.items.title': function (to) {
      this.initData();
    },
    'status.resize': function (to) {
      if (to === true) {
        /* height
        #pageMenu.height:60px
        #content.padding:40px(20x2)
        #item-list-title-header: 51px;
        #item-list-header: 45px;
        #searchBox.height:可変
        */

        /* width
        #side.width:60px;
        #content.padding:40px(20x2)
        scroll-bar:20px;
         */

        //let widthApp = document.getElementById('app').clientWidth;

        let spacer_height = 20;
        let spacer_width = 20;

        let searchBoxHeight = document.getElementById('searchBox').clientHeight;

        let targetElem;
        this.elemSize.listBody.height = this.elemSize.base.heightApp - (196 + spacer_height + searchBoxHeight);
        this.elemSize.listBody.width = this.elemSize.base.widthApp - (100 + spacer_width);


        targetElem = document.getElementById('item-list-body');
        targetElem.style.height = this.elemSize.listBody.height + "px";
        if (this.elemSize.base.widthApp <= 480) {
          targetElem.style.width = "100%";
        } else {
          targetElem.style.width = this.elemSize.listBody.width + "px";
        }

        let hearderElem = document.getElementById('item-list-header');
        if (this.elemSize.base.widthApp <= 480) {
          hearderElem.style.width = "100%";
        } else {
          hearderElem.style.width = this.elemSize.listBody.width + "px";
        }

        this.setResizeFlg(false);
      }
    },
  },
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/
    setMode: function (mode) {
      this.mode = mode;
      this.initData();
    },
    initSearch: function () {
      this.search.items = _.cloneDeep(this.search.itemsOrigin);
    },
    setInitAndEnableSearch: function (type) {
      let self = this;
      Object.keys(this.search.items.jobs).forEach(function (key) {
        if (key === type) {
          if (self.search.items.jobs[key] === true) {
            self.search.items.jobs[key] = false;
          } else {
            self.search.items.jobs[key] = true;
          }
        } else {
          self.search.items.jobs[key] = false;
        }
      });
    },
    showDetailPage: function (item_id) {
      let resolvedRoute = this.$router.resolve({
        name: 'detail',
        params: {id: item_id}
      });
      window.open(resolvedRoute.href, 'itemDetail' + item_id);
    },
    changeShowDtl: function (type) {
      if (type === 'on' && this.status.showDtl === false) {
        this.status.showDtl = true;
      }
      if (type === 'off' && this.status.showDtl === true) {
        this.status.showDtl = false;
      }
    },
    sortColumn: function (col) {
      if (col === 'default') {
        this.sort.col = 'default';
        this.sort.order = 'asc';
        this.initData();
      } else {
        if (col === this.sort.col) {
          if (this.sort.order === 'asc') {
            this.sort.order = 'desc';
          } else {
            this.sort.order = 'asc';
          }
        } else {
          this.sort.col = col;
          this.sort.order = 'desc';
        }
        if (this.sort.order === 'asc') {
          this.items[this.mode].items = commonFunc.sortList(this.sort.col, this.items[this.mode].items);
        } else if (this.sort.order === 'desc') {
          this.items[this.mode].items = commonFunc.sortListReverse(this.sort.col, this.items[this.mode].items);
        }


      }
    },
    setResizeFlg: function (val) {
      this.status.resize = val;
    },
    toggleSeachBox: function () {
      this.search.boxStatus = !this.search.boxStatus;
      this.$nextTick(function () {
        this.setResizeFlg(true);
      })
    },
    scrollItemList: function (e, type) {
      if (this.scroll.x !== e.target.scrollLeft) {
        this.scroll.x = e.target.scrollLeft;
        if (type === 'header') {
          document.getElementById('item-list-body').scrollLeft = this.scroll.x;
        } else if (type === 'body') {
          document.getElementById('item-list-header').scrollLeft = this.scroll.x;
        }
      }
    },

    /*************************************************
     * 算出
     *************************************************/
    getTitleEnLine: function (item) {
      return '[' + item.item_id + '] ' + item.title_en;
    },
    getPartsName: function (parts_type) {
      return commonFunc.getPartsName(parts_type);
    },
    getRankName: function (rarity) {
      return commonFunc.getRankName(rarity);
    },
    getJobEnable: function (value, jobs) {
      return ((value & jobs) === 0) ? '×' : '〇';
    },
    searchFilter: function (data) {
      let searchItems = this.search.items;
      let rarity = this.rarity_value;
      let parts = this.parts_value;
      let jobs = this.jobs_value;
      return data.items.filter(function (rec) {
        let result = true;
        if (rarity > 0) {
          if ((rarity & rec.rarity) === 0) {
            result = false;
          }
        }
        if (parts > 0) {
          if ((parts & rec.parts_type) === 0) {
            result = false;
          }
        }
        if (jobs > 0) {
          if ((jobs & rec.jobs) === 0) {
            result = false;
          }
        }

        if (searchItems.title !== '') {
          let title = rec.title + rec.title.toLowerCase() + rec.title_en + rec.title_en.toLowerCase() + commonFunc.hiragana2Katakana(rec.title) + commonFunc.katakana2Hiragana(rec.title);
          if (title.includes(searchItems.title) === false) {
            result = false;
          }
        }
        return result;
      });

    },
    calculateWindowWidth: function () {
      let heightApp = document.getElementById('app').clientHeight;
      let widthApp = document.getElementById('app').clientWidth;
      if (this.elemSize.base.heightApp !== heightApp || this.elemSize.base.widthApp !== widthApp) {
        this.elemSize.base.heightApp = heightApp;
        this.elemSize.base.widthApp = widthApp;
        this.setResizeFlg(true);
      }
    },
    /*************************************************
     * 動的クラス設定
     *************************************************/
    isActiveMode: function (mode) {
      let cname = {'is-active': false};

      if (this.mode === mode) {
        cname['is-active'] = true;
      }
      return cname;
    },
    setTempIdClass: function (amano) {
      let cname = {'is-amano': false};

      if (amano === true) {
        cname['is-amano'] = true;
      }
      return cname;
    },
    setRarityBgClass: function (rarity) {
      let cname = {
        'is-normal': false,
        'is-rare': false,
        'is-super-rare': false,
        'is-legend': false,
      };

      switch (parseInt(rarity, 10)) {
        case 2:
          cname['is-rare'] = true;
          break;
        case 4:
          cname['is-super-rare'] = true;
          break;
        case 8:
          cname['is-legend'] = true;
          break;
        default:
          cname['is-normal'] = true;
          break;
      }
      return cname;
    },
    setSearchBoxClass: function () {
      let cname = {
        'is-active': false
      };
      if (this.search.boxStatus === true) {
        cname['is-active'] = true;
      }
      return cname;
    },
    setSelectedRarityButtonClass: function (type) {
      let cname = {
        'is-info': false
      };
      if (this.search.items.rarity[type] === true) {
        cname['is-info'] = true;
      }
      return cname;
    },
    setSelectedPartsButtonClass: function (type) {
      let cname = {
        'is-info': false
      };
      if (this.search.items.parts[type] === true) {
        cname['is-info'] = true;
      }
      return cname;
    },
    setSelectedJobsButtonClass: function (type) {
      let cname = {
        'is-info': false
      };
      if (this.search.items.jobs[type] === true) {
        cname['is-info'] = true;
      }
      return cname;
    },
    setShowDtlClass: function (type) {
      let cname = {
        'is-info': false
      };
      if (type === 'on' && this.status.showDtl === true) {
        cname['is-info'] = true;
      }
      if (type === 'off' && this.status.showDtl === false) {
        cname['is-info'] = true;
      }
      return cname;
    },
    setJobDisableClass: function (value, jobs) {
      let cname = {'is-disabled': false}

      if ((value & jobs) === 0) {
        cname['is-disabled'] = true;
      }
      return cname;

    },
    setSortedClass: function (col) {
      let cname = {
        'is-ascending': false,
        'is-descending': false
      }
      if (this.sort.col === col) {
        if (this.sort.order === 'asc') {
          cname['is-ascending'] = true;
        } else if (this.sort.order === 'desc') {
          cname['is-descending'] = true;
        }
      }
      return cname;
    },
    /*************************************************
     * 初期処理
     *************************************************/
    initData: function () {
      return new Promise((resolve, reject) => {
        this.items[this.mode] = new Items();
        if (this.jsonData[this.mode]) {
          let data = this.jsonData[this.mode];
          if (data.items) {
            let items = Items.fromJson(data.items);
            let self = this;
            this.searchFilter(items).forEach(function (rec) {
              self.items[self.mode].items.push(rec);
            });

          }
        }
        resolve();
      });

    },
    firstLoad: function () {
      return new Promise((resolve, reject) => {
        let self = this;
        axios.get('./json/items.json')
            .then(function (response) {
              if (response.data && response.data.items) {
                let items = response.data.items;
                for (let i = 0; i < items.length; i++) {
                  if (parseInt(items[i].item_type, 10) === 0) {
                    self.jsonData.base.items.push(items[i]);
                  } else if (parseInt(items[i].item_type, 10) === 1) {
                    self.jsonData.cosplay.items.push(items[i]);
                  }
                }
              }
              resolve();
            });
      });
    },

    /*************************************************
     * コンポーネント連携
     *************************************************/
    setAuth: function (auth) {
      this.$emit('setAuth',auth);
    }


  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>
