import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

// firebaseConfigの値を元にfirebaseの初期化
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
// アナリティクスの初期化
const analytics = getAnalytics(firebaseApp);
const db = getFirestore(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);

export {
    firebaseApp,
    auth,
    analytics,
    db,
    firebaseStorage
}