import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import { faRankingStar } from '@fortawesome/free-solid-svg-icons';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faScaleBalanced } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { faFlask } from '@fortawesome/free-solid-svg-icons';
import { faFaceGrinHearts } from '@fortawesome/free-solid-svg-icons';

import { faComments } from '@fortawesome/free-regular-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

library.add(faRankingStar);
library.add(faChartColumn);
library.add(faShieldHalved);
library.add(faMinus);
library.add(faPlus);
library.add(faScaleBalanced);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faBolt);
library.add(faFlask);
library.add(faFaceGrinHearts);

library.add(faComments);
library.add(faCircleQuestion);

let app = createApp(App);

app.component('fa', FontAwesomeIcon )
    .use(router)
    .mount('#app');

