<template>
  <div id="contents" class="content is-flex is-liner">

    <!--
    <div id="side" class="fix" style="flex-basis:60px;min-width:60px;">
      <navbar></navbar>
    </div>
    -->

    <div id="main-content" class="grow is-flex is-vertical">

      <div id="content" class="is-flex is-vertical" v-if="status.loadData===true">

        <h2 v-text="setTitle()"></h2>

        <div class="item-detail has-pad-t20">

          <div class="card item-card">

            <div class="card-content">

              <div>

                <div class="is-flex-auto-vertical-wide is-liner">

                  <div class="item-detail-left is-flex is-vertical">

                    <div class="is-flex-auto-vertical is-liner">


                      <!-- サムネイル＋装備可能職 -->
                      <div class="fix has-pad-r20 has-pad-b20 is-flex is-vertical">

                        <img id="item-thumbnail" class="img-frame" v-bind:src="item.img_logo" alt="item.title_en">

                      </div>

                      <!-- 補足情報 -->
                      <div class="has-pad-r20 grow">
                        <div class="table-rap">
                          <table class="table is-bordered status-base is-semi-narrow">
                            <tbody>
                            <tr>
                              <th>種類</th>
                              <td><span v-text="getConsumptionItemTypeName(item.item_type)"></span></td>
                            </tr>
                            <tr>
                              <th>ID</th>
                              <td><span v-text="item.consumption_id"></span></td>
                            </tr>
                            <tr>
                              <th>レアリティ</th>
                              <td><span v-text="getRankName(item.rarity)"></span></td>
                            </tr>
                            <tr>
                              <th>発行上限</th>
                              <td><span v-text="item.maximum_number"></span></td>
                            </tr>
                            <tr>
                              <th>概要</th>
                              <td><div v-text="item.description"></div></td>
                            </tr>
                            <tr>
                              <th>取引回数</th>
                              <td><span v-text="item.statistics.count"></span></td>
                            </tr>
                            <tr>
                              <th>取引数量</th>
                              <td><span v-text="item.statistics.quantity"></span></td>
                            </tr>
                            <tr>
                              <th>取引総額</th>
                              <td><span v-text="setAmount(item.statistics.amount_total,'USDT')"></span></td>
                            </tr>

                            <tr>
                              <th>高値（取引単位）</th>
                              <td><span
                                  v-text="setAmount(item.statistics.amount_max,'USDT', item.statistics.amount_max_date)"></span>
                              </td>
                            </tr>
                            <tr>
                              <th>底値（取引単位）</th>
                              <td><span
                                  v-text="setAmount(item.statistics.amount_min,'USDT', item.statistics.amount_min_date)"></span>
                              </td>
                            </tr>

                            <tr>
                              <th>高値（単価）</th>
                              <td><span
                                  v-text="setAmount(item.statistics.unit_price_max,'USDT', item.statistics.unit_price_max_date)"></span>
                              </td>
                            </tr>
                            <tr>
                              <th>底値（単価）</th>
                              <td><span
                                  v-text="setAmount(item.statistics.unit_price_min,'USDT', item.statistics.unit_price_min_date)"></span>
                              </td>
                            </tr>

                            <tr>
                              <th>MV取引</th>
                              <td><span
                                  v-text="item.statistics.mv_count+' 回（計 '+setAmount(item.statistics.mv_amount,'MV')+'）'"></span>
                              </td>
                            </tr>

                            </tbody>
                          </table>

                        </div>

                      </div>

                    </div>

                  </div>

                  <div class="item-detail-right is-flex is-vertical">

                    <div class="is-flex is-liner has-pad-r20">

                      <div class="card has-width-full">
                        <div class="card-content">
                          <div class="content">
                            <lineGraph
                                :graph-data="setLineGraphData(item.graph.scale_volume,item.graph.volume,'取引件数',colors.volumeLineColor)">
                            </lineGraph>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="is-flex is-liner has-pad-t20 has-pad-r20">

                      <div class="card has-width-full">
                        <div class="card-content">
                          <div class="content">
                            <lineGraph
                                :graph-data="setLineGraphData(item.graph.scale_quantity,item.graph.quantity,'取引数量',colors.quantityLineColor)">
                            </lineGraph>
                          </div>
                        </div>
                      </div>

                    </div>


                    <div class="is-flex is-liner has-pad-t20 has-pad-r20">

                      <div class="card has-width-full">
                        <div class="card-content">
                          <div class="content">
                            <lineGraph
                                :graph-data="setLineGraphData(item.graph.scale_amount,item.graph.amount,'USDT（取引総額）',colors.amountLineColor)">
                            </lineGraph>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="is-flex is-liner has-pad-t20 has-pad-r20">

                      <div class="card has-width-full">
                        <div class="card-content">
                          <div class="content">
                            <lineGraph
                                :graph-data="setLineGraphData(item.graph.scale_unit_price,item.graph.unit_price,'USDT（取引単価）',colors.unitPriceLineColor)">
                            </lineGraph>
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>

                </div>

              </div>

            </div>

          </div>


        </div>


      </div>


    </div>


  </div>
</template>

<script>
import {commonFunc} from '@/models/commonFunc'
//import navbar from "./common/NaviHeader.vue";
import axios from "axios";
import {DetailConsumption} from "@/models/detailConsumption";

import lineGraph from './common/LineGraph.vue'

import * as _ from "lodash";

export default {
  components: {
//    'navbar': navbar,
    'lineGraph': lineGraph,
  },
  name: 'item',
  data() {
    return {
      mode: 'base', //base, cosplay
      id: 0,
      item: new DetailConsumption(),
      colors: {
        volumeLineColor: '#40a650',
        amountLineColor: '#b74747',
        quantityLineColor: '#40a650',
        unitPriceLineColor: '#b74747'
      },
      modalImgIndex: 0,
      status: {
        loadData: false,
        modal: false
      }
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id, 10);
    }
    if (this.id > 0) {
      this.initData();
    }

  },
  watch: {},
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/

    /*************************************************
     * 汎用処理
     *************************************************/
    setLineGraphData: function (scale, data, label, fill_color = '') {

      let viewData = {label: label, data: data, tension: 0.1, fill: true};
      if (fill_color) {
        viewData['borderColor'] = fill_color;
      }
      return {
        labels: scale,
        datasets: [viewData]
      };
    },


    /*************************************************
     * 算出
     *************************************************/
    getConsumptionItemTypeName: function (item_type) {
      return commonFunc.getConsumptionItemTypeName(item_type);
    },
    getRankName: function (rarity) {
      return commonFunc.getRankName(rarity);
    },
    setTitle: function () {
      let title = this.item.title;
      if (!title || title === '') {
        title = '日本語名称不明（' + this.item.title_en + '）';
      } else {
        title += '（' + this.item.title_en + '）';
      }
      return title;
    },
    setAmount: function (amount, currency = '', dateStr = '') {
      let amount_str = amount.toLocaleString();
      if (currency !== '') {
        amount_str += ' ' + currency;
      }
      if (dateStr !== '') {
        amount_str += '（' + dateStr + '）';
      }
      return amount_str;
    },

    /*************************************************
     * 動的クラス設定
     *************************************************/


    /*************************************************
     * 初期処理
     *************************************************/


    initData: function () {
      return new Promise((resolve, reject) => {
        let self = this;
        axios.get('./json/consumption/' + this.id + '.json')
            .then(function (response) {
              if (response.data) {
                self.item = DetailConsumption.fromJson(response.data);
                self.status.loadData = true;
              }
              resolve();
            });
      });
    }


  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>
