import * as moment from 'moment';
import * as Model from './base';
import {Consumption} from "./consumption";
import {MOMENT_FORMAT_PLAIN_SHORT} from "./base";

export class DetailConsumption extends Consumption {
    constructor(params = {}) {
        super();

        this.statistics = {
            count: 0,
            quantity: 0,
            amount_total: 0,
            amount_min: 0,
            amount_min_date: '',
            amount_max: 0,
            amount_max_date: '',
            unit_price_min: 0,
            unit_price_min_date: '',
            unit_price_max: 0,
            unit_price_max_date: '',
            mv_count: 0,
            mv_amount: 0
        }

        this.graph = {
            scale_amount: [],
            amount: [],
            scale_volume: [],
            volume: [],
            scale_unit_price: [],
            unit_price: [],
            scale_quantity: [],
            quantity: [],
        };

    }

    static fromJson(params) {
        let out = Consumption.fromJson(params, new DetailConsumption());

        out.statistics.count = parseInt(params.statistics.count, 10);
        out.statistics.quantity = parseInt(params.statistics.quantity, 10);
        out.statistics.amount_total = params.statistics.amount_total;

        out.statistics.amount_min = params.statistics.amount_min;
        out.statistics.amount_min_date = params.statistics.amount_min_date;
        out.statistics.amount_max = params.statistics.amount_max;
        out.statistics.amount_max_date = params.statistics.amount_max_date;

        out.statistics.unit_price_min = params.statistics.unit_price_min;
        out.statistics.unit_price_min_date = params.statistics.unit_price_min_date;
        out.statistics.unit_price_max = params.statistics.unit_price_max;
        out.statistics.unit_price_max_date = params.statistics.unit_price_max_date;

        out.statistics.mv_count = parseInt(params.statistics.mv_count, 10);
        out.statistics.mv_amount = params.statistics.mv_amount;

        if (params.graph.scale_amount) {
            out.graph.scale_amount = params.graph.scale_amount;
            out.graph.amount = params.graph.amount;
        }
        if (params.graph.scale_volume) {
            out.graph.scale_volume = params.graph.scale_volume;
            out.graph.volume = params.graph.volume;
        }
        if (params.graph.scale_unit_price) {
            out.graph.scale_unit_price = params.graph.scale_unit_price;
            out.graph.unit_price = params.graph.unit_price;
        }
        if (params.graph.scale_quantity) {
            out.graph.scale_quantity = params.graph.scale_quantity;
            out.graph.quantity = params.graph.quantity;
        }

        return out;
    }

}
