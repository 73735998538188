<template>
  <div id="contents" class="content is-flex-auto-vertical is-liner">

    <div id="side" class="fix" style="flex-basis:60px;min-width:60px;">
      <navbar
          :config="config"
          @setAuth="setAuth"></navbar>
    </div>

    <div id="main-content" class="grow is-flex is-vertical">

      <div id="pageMenu" class="fix is-flex-auto-vertical is-liner f_left">

        <div class="navi-title is-flex is-liner f_left f_vcenter">
          <h1 class="grow site-title has-pad-l20">GK Market Analyzer</h1>
        </div>
        <div class="spacer grow is-flex is-liner f_left f_vcenter"></div>

      </div>

      <div id="content" class="is-flex is-vertical">


        <h2 id="item-list-title-header">消費アイテム</h2>

        <div class="item-list-with-thumbnail is-flex is-vertical">

          <div id="item-list-header" class="item-list-header" v-on:scroll="scrollConsumptionList($event, 'header')">

            <div class="status-parm-header is-flex is-liner">

              <div class="status-parm-header-rap is-flex is-liner grow">
                <div class="grow title" v-on:click="sortColumn('default')">&nbsp;</div>
              </div>

            </div>

          </div>

          <div id="item-list-body" class="grow item-list-body" v-on:scroll="scrollConsumptionList($event, 'body')">

            <template v-for="item in items.items" :key="item.consumption_id">

              <div class="card item-card" v-bind:class="setRarityBgClass(item.rarity)">

                <div class="card-content">

                  <div class="is-flex is-liner">

                    <div class="is-flex is-liner fix" style="flex-basis:470px;min-width:470px;">

                      <div class="card-image fix"
                           style="flex-basis:70px;min-width:70px;height:60px;">
                        <img class="img-frame-mini has-link" v-bind:src="item.img_logo" alt="item.title_en"
                             loading="lazy"
                             v-on:click="showDetailPage(item.consumption_id)">
                      </div>

                      <div class="content grow is-flex is-vertical">

                        <div class="line">
                          <p class="name_en" v-text="getTitleEnLine(item)"></p>
                          <div>
                            <span v-text="'['+getConsumptionItemTypeName(item.item_type)+'] '"></span>
                            <span class="title name" v-text="item.title"></span>
                          </div>

                        </div>


                      </div>

                    </div>

                    


                  </div>


                </div>
              </div>


            </template>

          </div>


        </div>

      </div>

      <div id="searchBox" class="fix searchBox" v-bind:class="setSearchBoxClass()">
        <div class="searchBoxRap is-flex is-vertical">
          <div class="baseSearchBox is-flex is-liner">

            <div class="searchLine is-flex is-liner">
              <div class="fix dummySpace" style="flex-basis:60px;min-width:60px;"></div>
              <div class="grow baseSearchLine is-flex is-liner f_vcenter has-flex-rap">
                <div class="fix searchLabel" style="flex-basis:60px;">名称</div>
                <div class="fix searchColumn" style="flex-basis:300px;min-width:300px;">
                  <input type="text" class="input" v-model="search.items.title">
                </div>

                <div class="fix searchColumn has-pad-l10" style="flex-basis:100px;min-width:100px;">
                  <button class="button"
                          v-on:click="initSearch()">クリア
                  </button>
                </div>

                <div class="fix searchColumn is-flex is-liner" style="flex-basis:150px;min-width:150px;">

                  <div class="buttons has-addons selectGroupButtons">
                    <button class="button"
                            v-bind:class="setSelectedRarityButtonClass('normal')"
                            v-on:click="search.items.rarity.normal=!search.items.rarity.normal">Ｎ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedRarityButtonClass('rare')"
                            v-on:click="search.items.rarity.rare=!search.items.rarity.rare">Ｒ
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedRarityButtonClass('superRare')"
                            v-on:click="search.items.rarity.superRare=!search.items.rarity.superRare">SR
                    </button>
                    <button class="button"
                            v-bind:class="setSelectedRarityButtonClass('legend')"
                            v-on:click="search.items.rarity.legend=!search.items.rarity.legend">LR
                    </button>
                  </div>

                </div>

                <div class="fix searchColumn" style="flex-basis:320px;min-width:320px;">

                  <div class="buttons has-addons selectGroupButtons">
                    <button class="button"
                            v-bind:class="setItemTypeButtonClass('coin')"
                            v-on:click="search.items.itemType.coin=!search.items.itemType.coin">ｺｲﾝ
                    </button>
                    <button class="button"
                            v-bind:class="setItemTypeButtonClass('reinforce')"
                            v-on:click="search.items.itemType.reinforce=!search.items.itemType.reinforce">強化
                    </button>
                    <button class="button"
                            v-bind:class="setItemTypeButtonClass('consumption')"
                            v-on:click="search.items.itemType.consumption=!search.items.itemType.consumption">消耗品
                    </button>
                    <button class="button"
                            v-bind:class="setItemTypeButtonClass('food')"
                            v-on:click="search.items.itemType.food=!search.items.itemType.food">食料
                    </button>
                    <button class="button"
                            v-bind:class="setItemTypeButtonClass('material')"
                            v-on:click="search.items.itemType.material=!search.items.itemType.material">素材
                    </button>
                    <button class="button"
                            v-bind:class="setItemTypeButtonClass('voting')"
                            v-on:click="search.items.itemType.voting=!search.items.itemType.voting">投票券
                    </button>

                  </div>

                </div>




              </div>
            </div>

          </div>


        </div>
      </div>

    </div>


  </div>
</template>

<script>
import {commonFunc} from '@/models/commonFunc'
import navbar from "./common/NaviHeader.vue";
import axios from "axios";

import {Conf} from "@/models/conf";
import {Consumptions, Consumption} from "@/models/consumption";

import * as _ from "lodash";

export default {
  components: {
    'navbar': navbar,
  },
  name: 'item',
  props: {
    config: new Conf()
  },
  data() {
    return {
      mode: 'base', //base, cosplay
      elemSize: {
        base: {
          widthApp: 0,
          heightApp: 0,
        },
        listBody: {
          width: 0,
          height: 650,
        }
      },
      jsonData: new Consumptions(),
      items: new Consumptions(),
      search: {
        boxStatus: false,
        items: {
          title: '',
          rarity: {
            normal: false,
            rare: false,
            superRare: false,
            legend: false
          },
          itemType: {
            coin: false,
            reinforce: false,
            consumption: false,
            food: false,
            material: false,
            voting: false
          }
        },
        itemsOrigin: {}
      },
      sort: {
        col: 'default',
        order: 'asc'
      },
      scroll: {
        x: 0
      },
      status: {
        showDtl: true,
        resize: false,
      }
    }
  },
  computed: {
    rarity_value: function () {
      let val = 0;
      val += (this.search.items.rarity.normal === true) ? 1 : 0;
      val += (this.search.items.rarity.rare === true) ? 2 : 0;
      val += (this.search.items.rarity.superRare === true) ? 4 : 0;
      val += (this.search.items.rarity.legend === true) ? 8 : 0;
      return val;
    },
    item_type: function () {
      let val = 0;
      val += (this.search.items.itemType.coin === true) ? 1 : 0;
      val += (this.search.items.itemType.reinforce === true) ? 2 : 0;
      val += (this.search.items.itemType.consumption === true) ? 4 : 0;
      val += (this.search.items.itemType.food === true) ? 8 : 0;
      val += (this.search.items.itemType.material === true) ? 16 : 0;
      val += (this.search.items.itemType.voting === true) ? 32 : 0;
      return val;
    },
  },
  created() {
  },
  mounted() {
    this.search.itemsOrigin = _.cloneDeep(this.search.items);
    this.firstLoad()
        .then(this.initData);
    this.calculateWindowWidth();
    this.setResizeFlg(true);
    window.addEventListener('resize', this.calculateWindowWidth);
  },
  watch: {
    'rarity_value': function (to) {
      this.initData();
    },
    'item_type': function (to) {
      this.initData();
    },
    'search.items.title': function (to) {
      this.initData();
    },
    'status.resize': function (to) {
      if (to === true) {
        /* height
        #pageMenu.height:60px
        #content.padding:40px(20x2)
        #item-list-title-header: 51px;
        #item-list-header: 45px;
        #searchBox.height:可変
        */

        /* width
        #side.width:60px;
        #content.padding:40px(20x2)
        scroll-bar:20px;
         */

        //let widthApp = document.getElementById('app').clientWidth;

        let spacer_height = 20;
        let spacer_width = 20;

        let searchBoxHeight = document.getElementById('searchBox').clientHeight;

        let targetElem;
        this.elemSize.listBody.height = this.elemSize.base.heightApp - (196 + spacer_height + searchBoxHeight);
        this.elemSize.listBody.width = this.elemSize.base.widthApp - (100 + spacer_width);


        targetElem = document.getElementById('item-list-body');
        targetElem.style.height = this.elemSize.listBody.height + "px";
        if (this.elemSize.base.widthApp <= 480) {
          targetElem.style.width = "100%";
        } else {
          targetElem.style.width = this.elemSize.listBody.width + "px";
        }

        let hearderElem = document.getElementById('item-list-header');
        if (this.elemSize.base.widthApp <= 480) {
          hearderElem.style.width = "100%";
        } else {
          hearderElem.style.width = this.elemSize.listBody.width + "px";
        }

        this.setResizeFlg(false);
      }
    },
  },
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/
    setMode: function (mode) {
      this.mode = mode;
      this.initData();
    },
    initSearch: function () {
      this.search.items = _.cloneDeep(this.search.itemsOrigin);
    },
    setInitAndEnableSearch: function (type) {
      let self = this;
      Object.keys(this.search.items.jobs).forEach(function (key) {
        if (key === type) {
          if (self.search.items.jobs[key] === true) {
            self.search.items.jobs[key] = false;
          } else {
            self.search.items.jobs[key] = true;
          }
        } else {
          self.search.items.jobs[key] = false;
        }
      });
    },
    showDetailPage: function (consumption_id) {
      let resolvedRoute = this.$router.resolve({
        name: 'detail-consumption',
        params: {id: consumption_id}
      });
      window.open(resolvedRoute.href, 'consumptionDetail' + consumption_id);
    },
    changeShowDtl: function (type) {
      if (type === 'on' && this.status.showDtl === false) {
        this.status.showDtl = true;
      }
      if (type === 'off' && this.status.showDtl === true) {
        this.status.showDtl = false;
      }
    },
    sortColumn: function (col) {

    },
    setResizeFlg: function (val) {
      this.status.resize = val;
    },
    toggleSeachBox: function () {
      this.search.boxStatus = !this.search.boxStatus;
      this.$nextTick(function () {
        this.setResizeFlg(true);
      })
    },
    scrollConsumptionList: function (e, type) {
      if (this.scroll.x !== e.target.scrollLeft) {
        this.scroll.x = e.target.scrollLeft;
        if (type === 'header') {
          document.getElementById('item-list-body').scrollLeft = this.scroll.x;
        } else if (type === 'body') {
          document.getElementById('item-list-header').scrollLeft = this.scroll.x;
        }
      }
    },

    /*************************************************
     * 算出
     *************************************************/
    getTitleEnLine: function (item) {
      return '[' + item.consumption_id + '] ' + item.title_en;
    },
    getConsumptionItemTypeName: function (item_type) {
      return commonFunc.getConsumptionItemTypeName(item_type);
    },
    getRankName: function (rarity) {
      return commonFunc.getRankName(rarity);
    },
    searchFilter: function (data) {
      let searchConsumptions = this.search.items;
      let rarity = this.rarity_value;
      let item_type = this.item_type;
      return data.items.filter(function (rec) {
        let result = true;
        if (rarity > 0) {
          if ((rarity & rec.rarity) === 0) {
            result = false;
          }
        }
        if (item_type > 0) {
          if ((item_type & rec.item_type) === 0) {
            result = false;
          }
        }

        if (searchConsumptions.title !== '') {
          let title = rec.title + rec.title.toLowerCase() + rec.title_en + rec.title_en.toLowerCase() + commonFunc.hiragana2Katakana(rec.title) + commonFunc.katakana2Hiragana(rec.title);
          if (title.includes(searchConsumptions.title) === false) {
            result = false;
          }
        }
        return result;
      });

    },
    calculateWindowWidth: function () {
      let heightApp = document.getElementById('app').clientHeight;
      let widthApp = document.getElementById('app').clientWidth;
      if (this.elemSize.base.heightApp !== heightApp || this.elemSize.base.widthApp !== widthApp) {
        this.elemSize.base.heightApp = heightApp;
        this.elemSize.base.widthApp = widthApp;
        this.setResizeFlg(true);
      }
    },
    /*************************************************
     * 動的クラス設定
     *************************************************/
    isActiveMode: function (mode) {
      let cname = {'is-active': false};

      if (this.mode === mode) {
        cname['is-active'] = true;
      }
      return cname;
    },
    setTempIdClass: function (amano) {
      let cname = {'is-amano': false};

      if (amano === true) {
        cname['is-amano'] = true;
      }
      return cname;
    },
    setRarityBgClass: function (rarity) {
      let cname = {
        'is-normal': false,
        'is-rare': false,
        'is-super-rare': false,
        'is-legend': false,
      };

      switch (parseInt(rarity, 10)) {
        case 2:
          cname['is-rare'] = true;
          break;
        case 4:
          cname['is-super-rare'] = true;
          break;
        case 8:
          cname['is-legend'] = true;
          break;
        default:
          cname['is-normal'] = true;
          break;
      }
      return cname;
    },
    setSearchBoxClass: function () {
      let cname = {
        'is-active': false
      };
      if (this.search.boxStatus === true) {
        cname['is-active'] = true;
      }
      return cname;
    },
    setSelectedRarityButtonClass: function (type) {
      let cname = {
        'is-info': false
      };
      if (this.search.items.rarity[type] === true) {
        cname['is-info'] = true;
      }
      return cname;
    },
    setItemTypeButtonClass: function (type) {
      let cname = {
        'is-info': false
      };
      if (this.search.items.itemType[type] === true) {
        cname['is-info'] = true;
      }
      return cname;
    },
    setSelectedJobsButtonClass: function (type) {
      let cname = {
        'is-info': false
      };
      if (this.search.items.jobs[type] === true) {
        cname['is-info'] = true;
      }
      return cname;
    },
    setShowDtlClass: function (type) {
      let cname = {
        'is-info': false
      };
      if (type === 'on' && this.status.showDtl === true) {
        cname['is-info'] = true;
      }
      if (type === 'off' && this.status.showDtl === false) {
        cname['is-info'] = true;
      }
      return cname;
    },
    setJobDisableClass: function (value, jobs) {
      let cname = {'is-disabled': false}

      if ((value & jobs) === 0) {
        cname['is-disabled'] = true;
      }
      return cname;

    },
    setSortedClass: function (col) {
      let cname = {
        'is-ascending': false,
        'is-descending': false
      }
      if (this.sort.col === col) {
        if (this.sort.order === 'asc') {
          cname['is-ascending'] = true;
        } else if (this.sort.order === 'desc') {
          cname['is-descending'] = true;
        }
      }
      return cname;
    },
    /*************************************************
     * 初期処理
     *************************************************/
    initData: function () {
      return new Promise((resolve, reject) => {
        this.items = new Consumptions();
        if (this.jsonData) {
          let data = this.jsonData;
          if (data.items) {
            let items = Consumptions.fromJson(data.items);
            let self = this;
            this.searchFilter(items).forEach(function (rec) {
              self.items.items.push(rec);
            });

          }
        }
        resolve();
      });

    },
    firstLoad: function () {
      return new Promise((resolve, reject) => {
        let self = this;
        axios.get('./json/consumptions.json')
            .then(function (response) {
              if (response.data && response.data.items) {
                let items = response.data.items;
                for (let i = 0; i < items.length; i++) {
                  self.jsonData.items.push(items[i]);
                }
              }
              resolve();
            });
      });
    },

    /*************************************************
     * コンポーネント連携
     *************************************************/
    setAuth: function (auth) {
      this.$emit('setAuth',auth);
    }


  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>
