import * as moment from 'moment';
import * as Model from './base';
import {Item} from "./item";
import {MOMENT_FORMAT_PLAIN_SHORT} from "./base";

export class Detail extends Item {
    constructor(params = {}) {
        super();

        this.status_max = {
            hp: 0,
            mp: 0,
            attack: 0,
            defense: 0,
            magic_attack: 0,
            atk_spd: 0,
            str: 0,
            vit: 0,
            agi: 0,
            inte: 0,
            dex: 0,
            mnd: 0,
            physical_cri: 0,
            physical_cri_multi: 7,
            magic_cri: 0,
            magic_cri_multi: 0,
            cast_spd: 0,
            def_proficiency: 5,
            guard: 4,
            guard_effect: 7,
            physical_resist: 0,
            magic_resist: 0,
            fire_resist: 7,
            wind_resist: 8,
            water_resist: 6,
            earth_resist: 10,
            holy_resist: 0,
            dark_resist: 0,
            critical_resist: 8,
            sleep_resist: 6,
            stun_resist: 7,
            poison_resist: 6,
            silence_resist: 8,
            root_resist: 0,
            snare_resist: 0,
            item_drop_rate: 0
        };

        this.statistics = {
            count: 0,
            amount_total: 0,
            amount_min: 0,
            amount_min_date: '',
            amount_max: 0,
            amount_max_date: '',
            amount_avg: 0,
            amount_median: 0,
            amount_avg_recently: 0,
            amount_median_recently: 0,
            mv_count: 0,
            mv_amount: 0
        }

        this.graph = {
            scale_amount: [],
            amount: [],
            scale_volume: [],
            volume: []
        };

        this.setup_logo = [];

    }

    static fromJson(params) {
        let out = Item.fromJson(params, new Detail());

        //status_max
        if (params.status_max) {
            Object.keys(params.status_max).forEach(function (column) {
                out.status_max[column] = parseInt(params.status_max[column], 10);
            });
        }

        out.statistics.count = parseInt(params.statistics.count, 10);
        out.statistics.amount_total = params.statistics.amount_total;
        out.statistics.amount_min = params.statistics.amount_min;
        out.statistics.amount_min_date = params.statistics.amount_min_date;
        out.statistics.amount_max = params.statistics.amount_max;
        out.statistics.amount_max_date = params.statistics.amount_max_date;
        out.statistics.amount_avg = params.statistics.amount_avg;
        out.statistics.amount_median = params.statistics.amount_median;
        out.statistics.amount_avg_recently = params.statistics.amount_avg_recently;
        out.statistics.amount_median_recently = params.statistics.amount_median_recently;

        out.statistics.mv_count = parseInt(params.statistics.mv_count, 10);
        out.statistics.mv_amount = params.statistics.mv_amount;

        if (params.graph.scale_amount) {
            out.graph.scale_amount = params.graph.scale_amount;
            out.graph.amount = params.graph.amount;
        }
        if (params.graph.scale_volume) {
            out.graph.scale_volume = params.graph.scale_volume;
            out.graph.volume = params.graph.volume;
        }

        if(params.setup_logo && params.setup_logo.length>0){
            for(let i=0;i<params.setup_logo.length;i++){
                let img_logo = params.setup_logo[i];

                if (out.image_mode === 'min') {
                    out.setup_logo.push('./nft_images_min/' + img_logo.replace(/png|gif/g, 'jpg'))
                } else {
                    out.setup_logo.push('./nft_images/' + img_logo);
                }
            }
        }

        return out;
    }

}
