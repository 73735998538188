<template>
  <div id="contents" class="content is-flex-auto-vertical is-liner">

    <div id="side" class="fix" style="flex-basis:60px;min-width:60px;">
      <navbar
          :config="config"
          @setAuth="setAuth"></navbar>
    </div>

    <div id="main-content" class="grow is-flex is-vertical">

      <div id="pageMenu" class="fix is-flex-auto-vertical is-liner f_left">
        <div class="navi-title is-flex is-liner f_left f_vcenter">
          <h1 class="grow site-title has-pad-l20">GK Market Analyzer</h1>
        </div>

        <div class="spacer grow is-flex is-liner f_left f_vcenter"></div>
      </div>


      <div id="content" class="is-flex is-vertical">

        <h2>GK Market Analyzerについて</h2>

        <div class="help-contents">

          <div class="is-flex is-liner">

            <div class="card has-margin-t20 has-width-full">
              <div class="card-content">
                <div class="content">

                  <div>
                    <h4 class="help-title" data-number="01">表示内容について</h4>
                    <div class="help-description">
                      元素騎士マーケットプレイス内の
                      <a href="https://market.genso.game/ja/marketplace/equipments/transaction-log" target="_blank">装備のトランザクションログ</a>
                      に掲載されている情報を控え、独自に集計して表示しています。
                    </div>

                  </div>


                  <div>
                    <h4 class="help-title" data-number="02">情報の更新タイミングについて</h4>
                    <div class="help-description">
                      更新頻度は１日１回です。0:00以降に、前日分の取引内容を適用しています。
                    </div>

                  </div>

                  <div>
                    <h4 class="help-title" data-number="03">MV指定の取引について</h4>
                    <div class="help-description">
                    KuCoin,Krakenなど、取引所の当日の終値（MV/USDT）でUSDTに換算してから集計を行っているため、実際の金額とは若干ずれます。
                    </div>
                  </div>

                  <div>
                    <h4 class="help-title" data-number="04">サムネイル画像について</h4>
                    <div class="help-description">
                      マーケットプレイスで表示されているサムネイル画像を縮小し、当サイト内に配置して利用しています。
                    </div>

                  </div>

                  <div>
                    <h4 class="help-title" data-number="05">装備の日本語名について</h4>
                    <div class="help-description">
                      ゲーム内で確認する以外の方法が不明なため、未確認のものは空欄です。
                    </div>

                  </div>

                  <div>
                    <h4 class="help-title" data-number="06">掲載情報の利用について</h4>
                    <div class="help-description">
                      掲載内容に誤りがあった場合を含み、当サイトの情報を利用することにより発生したトラブルや損害、損失について<br>
                      責任は負えませんので、あくまでひとつの参考情報としてご利用ください。
                    </div>

                  </div>

                </div>
              </div>
            </div>


          </div>




        </div>


      </div>

    </div>

  </div>
</template>

<script>
import {commonFunc} from '@/models/commonFunc'
import navbar from "./common/NaviHeader.vue";
import axios from "axios";

import {Conf} from "@/models/conf";

export default {
  components: {
    'navbar': navbar,
  },
  name: 'help',
  props: {
    config: new Conf()
  },
  data() {
    return {

    }
  },
  computed: {
  },
  created() {
  },
  mounted() {

  },
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/


    /*************************************************
     * 算出処理
     *************************************************/

    /*************************************************
     * 初期処理
     *************************************************/

    /*************************************************
     * コンポーネント連携
     *************************************************/
    setAuth: function (auth) {
      this.$emit('setAuth',auth);
    }


  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>
