import * as moment from 'moment';
import * as Model from './base';

export class Mv extends Model.modelItemBase {
    constructor(params = {}) {
        // _inputErrors
        super();

        this.scale = [];
        this.amount = [];
        this.volume = [];
    }

    static fromJson(params) {
        let out = new Mv();

        if (params.scale.length > 0) {
            out.scale = params.scale;
        }
        if (params.amount.length > 0) {
            out.amount = params.amount;
        }
        if (params.volume.length > 0) {
            out.volume = params.volume;
        }

        return out;
    }

}
