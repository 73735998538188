import * as moment from 'moment';
import * as Model from './base';
import {MOMENT_FORMAT_PLAIN_SHORT} from "./base";

export class Trade extends Model.modelItemBase {
    constructor(params = {}) {
        super();

        this.id = 0;
        this.history_date = '';
        this.amount_usdt = 0;
        this.level = 0;
        this.hp = 0;
        this.mp = 0;
        this.attack = 0;
        this.defense = 0;
        this.magic_attack = 0;
        this.atk_spd = 0;
        this.str = 0;
        this.vit = 0;
        this.agi = 0;
        this.inte = 0;
        this.dex = 0;
        this.mnd = 0;
        this.physical_cri = 0;
        this.physical_cri_multi = 0;
        this.magic_cri = 0;
        this.magic_cri_multi = 0;
        this.cast_spd = 0;
        this.def_proficiency = 0;
        this.guard = 0;
        this.guard_effect = 0;
        this.item_drop_rate = 0;
    }

    static fromJson(params) {
        let out = new Trade();

        out.id = parseInt(params.id, 10);
        out.history_date = params.history_date;
        out.amount_usdt = params.amount_usdt;

        out.level = parseInt(params.level, 10);
        out.hp = parseInt(params.hp, 10);
        out.mp = parseInt(params.mp, 10);
        out.attack = parseInt(params.attack, 10);
        out.defense = parseInt(params.defense, 10);
        out.magic_attack = parseInt(params.magic_attack, 10);
        out.atk_spd = parseInt(params.atk_spd, 10);
        out.str = parseInt(params.str, 10);
        out.vit = parseInt(params.vit, 10);
        out.agi = parseInt(params.agi, 10);
        out.inte = parseInt(params.inte, 10);
        out.dex = parseInt(params.dex, 10);
        out.mnd = parseInt(params.mnd, 10);
        out.physical_cri = parseInt(params.physical_cri, 10);
        out.physical_cri_multi = parseInt(params.physical_cri_multi, 10);
        out.magic_cri = parseInt(params.magic_cri, 10);
        out.magic_cri_multi = parseInt(params.magic_cri_multi, 10);
        out.cast_spd = parseInt(params.cast_spd, 10);
        out.def_proficiency = parseInt(params.def_proficiency, 10);
        out.guard = parseInt(params.guard, 10);
        out.guard_effect = parseInt(params.guard_effect, 10);
        out.item_drop_rate = parseInt(params.item_drop_rate, 10);

        return out;
    }

}

export class Trades extends Model.modelBase {
    constructor(lists = []) {
        super(lists);
    }

    static fromJson(params = {}) {
        let items = [];
        let id=1;
        params.forEach((item) => {
            item.id=id;
            items.push(Trade.fromJson(item));
            id++;
        });
        return new Trades(items);
    }

}