import * as _ from "lodash";

export const MOMENT_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const MOMENT_FORMAT_PLAIN_DATE = 'YYYY-MM-DD';
export const MOMENT_FORMAT_PLAIN_DISPLAY = 'YYYY/MM/DD';
export const MOMENT_FORMAT_PLAIN_SHORT = 'YYYY/MM/DD HH:mm';

export class modelItemBase {
  constructor() {
    let _inputErrors = [];

    this.setError = function (error) {
      _inputErrors.push(error);
    };

    this.getErrors = function () {
      return _inputErrors.slice(0);
    };

    this.clearErrors = function () {
      _inputErrors = [];
    };
  }

  filterErrorsByName(propName) {
    return this.getErrors().filter((item) => {
      return item.property === propName;
    }) || [];
  }

}

export class modelBase {
  constructor(items) {
    this.items = items;
    let _inputErrors = [];
    this.setError = function (error) {
      _inputErrors.push(error);
    };

    this.getErrors = function () {
      return _inputErrors.slice(0);
    };

    this.clearErrors = function () {
      _inputErrors = [];
    };
  }
}

