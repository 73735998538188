<template>
  <div id="contents" class="content is-flex-auto-vertical is-liner">

    <div id="side" class="fix" style="flex-basis:60px;min-width:60px;">
      <navbar
          :config="config"
          @setAuth="setAuth"></navbar>
    </div>


    <div id="main-content" class="grow is-flex is-vertical">

      <div id="pageMenu" class="fix is-flex-auto-vertical is-liner f_left">

        <div class="navi-title is-flex is-liner f_left f_vcenter">
          <h1 class="grow site-title has-pad-l20">GK Market Analyzer</h1>
        </div>

        <div class="spacer grow is-flex is-liner f_left f_vcenter"></div>
      </div>


      <div id="content" class="is-flex is-vertical">

        <h2 class="fix is-flex is-liner f_left" style="flex-basis:51px;min-height:51px;">
          <div class="grow">こめんと</div>
          <div class="fix is-flex is-liner f_right" style="flex-basis:150px;min-width:150px;">
            <button class="button" v-if="status.login===false" v-on:click="login($event)">ログイン</button>
            <button class="button" v-if="status.login===true" v-on:click="logout($event)">ログアウト</button>
          </div>
        </h2>

        <div id="comments-area" class="grow comments-area">
          <div id="comments-area-rap" class="card">
            <div id="comments-area-body" class="card-content is-flex is-vertical">
              <div class="fix comment-main" style="flex-basis:400px;min-height:400px;">

                <template v-for="rec in list" v-bind:key="rec.id">

                  <div class="comment-box">
                    <div class="is-flex is-liner" v-bind:class="setCommentClass(rec.uid)">

                      <div class="fix is-flex is-liner f_left has-pad-t5" style="flex-basis:200px;"
                           v-if="this.isMyComment(rec.uid)===false">
                        <div class="tag has-width-full name-label" v-text="rec.name"></div>
                      </div>

                      <div class="grow">
                        <div class="card">
                          <div class="card-content">
                            <div v-text="rec.comment" style="word-break : break-all;"></div>
                            <div class="title-line is-flex is-liner f_right has-pad-r10">
                              <span v-text="rec.dt"></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="fix is-flex is-liner f_right has-pad-t5" style="flex-basis:200px;"
                           v-if="this.isMyComment(rec.uid)===true">
                        <div class="tag has-width-full name-label" v-text="rec.name"></div>
                      </div>

                    </div>
                  </div>

                </template>


              </div>
              <div class="input-area grow is-flex is-liner"
                   v-if="status.login===true">
                <div class="fix has-pad-r10" style="flex-basis:200px;min-width:200px;">
                  <input type="text" class="input" placeholder="ねーむ" v-model="entry.name" maxlength="30">
                </div>
                <div class="grow">
                  <textarea class="textarea" v-model="entry.comment" maxlength="400"></textarea>
                </div>
                <div class="fix has-pad-l10" style="flex-basis:80px;min-width:80px;">
                  <button class="button is-warning has-height-full" v-on:click="sendMes()"
                          v-bind:disabled="canSend===false">
                    <fa icon="bolt" class="fa-xl"/>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>

    </div>

  </div>
</template>

<script>
import {commonFunc} from '@/models/commonFunc'
import navbar from "./common/NaviHeader.vue";
import {firebaseApp, auth, analytics, db, firebaseStorage} from '@/firebase'
import {doc, setDoc, getDoc, getDocs, collection, query, orderBy, limit} from "firebase/firestore";

import {Conf} from "@/models/conf";

import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithRedirect,
  signOut,
} from "firebase/auth"

export default {
  components: {
    'navbar': navbar,
  },
  name: 'comment',
  props: {
    config: new Conf()
  },
  data() {
    return {
      credential: null,
      currentUser: {},  // ユーザー情報
      list: [],  // 取得したメッセージを入れる配列
      entry: {
        uid: '',
        name: '',
        comment: '',
        dt: ''
      },
      status: {
        login: false
      }
    }
  },
  computed: {
    canSend: function () {
      if (this.entry.uid === '') {
        return false;
      } else if (this.entry.name === '' || this.entry.comment === '') {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    'entry.uid': function (to) {
      if (to) {
        this.getSetName(to);
      }
    }
  },
  created() {
  },
  mounted() {

    if (this.config.auth && this.config.auth > 0) {
      // ログインしているユーザーを取得する
      onAuthStateChanged(auth, (user) => {
        if (user !== null) {
          this.currentUser = user;
          this.entry.uid = this.currentUser.uid;
          if (this.currentUser.displayName) {
            this.entry.name = this.currentUser.displayName;
          }
          this.entry.comment = '';
          this.status.login = true;
        } else {
          this.clearEntry(true);
          this.status.login = false;
        }
      });

      this.getList();
    } else {
      this.$router.push('/');
    }


  },
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/
    login: async function (event) {
      try {
        event.preventDefault()
        const provider = new GoogleAuthProvider()
        await signInWithRedirect(auth, provider)
      } catch (err) {
        console.log('login error', err.message);
      }

    },
    logout: async function (event) {
      try {
        event.preventDefault()
        await signOut(auth)
      } catch (err) {
        console.log('logout err', err.message);
      }

    },
    sendMes: async function () {

      if (this.canSend === true) {

        let dt = commonFunc.getNow();
        let key = commonFunc.getTs() + '-' + this.entry.uid;

        await setDoc(doc(db, "user", this.entry.uid), {
          uid: this.entry.uid,
          name: this.entry.name,
          dt: dt
        });

        await setDoc(doc(db, "comments", key), {
          uid: this.entry.uid,
          name: this.entry.name,
          comment: (this.entry.comment).substring(0, 200),
          dt: dt
        });
        this.$nextTick(function () {
          this.clearEntry();
          this.getList();
        })
      }
    },


    /*************************************************
     * 汎用処理
     *************************************************/
    clearEntry: function (all = false) {
      this.entry.comment = '';
      this.entry.dt = '';
      if (all === true) {
        this.entry.uid = '';
        this.entry.name = '';
      }
    },
    getSetName: async function (uid = '') {
      if (uid !== '') {
        let docRef = doc(db, "user", uid);
        let docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          this.entry.name = docSnap.data().name;
        }

      }

    },
    getList: function () {
      this.list = [];
      let commentsRef = collection(db, "comments");
      getDocs(query(commentsRef, orderBy("dt", "desc"), limit(100))).then(snapshot => {

        snapshot.forEach(doc => {
          this.list.push(
              {
                id: doc.id,
                dt: doc.data().dt,
                uid: doc.data().uid,
                name: doc.data().name,
                comment: doc.data().comment,
              }
          );
        })

      })
    },

    /*************************************************
     * 算出処理
     *************************************************/
    isMyComment: function (uid) {
      return uid === this.entry.uid;
    },

    /*************************************************
     * 動的クラス設定
     *************************************************/
    setCommentClass: function (uid) {
      let cname = {
        'self-comment': false,
        'other-comment': false
      };

      if (this.isMyComment(uid) === true) {
        cname['self-comment'] = true;
      } else {
        cname['other-comment'] = true;
      }
      return cname;
    },

    /*************************************************
     * コンポーネント連携
     *************************************************/
    setAuth: function (auth) {
      this.$emit('setAuth', auth);
    }


  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>
