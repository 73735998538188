<template>
  <Line :data="chartData" :options="chartOptions"/>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import {Line} from 'vue-chartjs'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

export default {
  components: {Line},
  name: 'App',
  props: {
    graphData: {
      labels:[],
      datasets:[{data:[]}]
    }
  },
  computed: {
    chartData() {
      return this.graphData
    },
    chartOptions() {
      return this.options
    }
  },
  watch: {

  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false

      }
    }
  },
  mounted() {

    this.initData();
  },
  methods: {


    /*************************************************
     * 初期処理
     *************************************************/
    initData: function () {

    }
  }
}
</script>