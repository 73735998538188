<template>
  <Bar :data="chartData" :options="chartOptions"/>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import {Bar} from 'vue-chartjs'
import * as _ from "lodash";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
  components: {Bar},
  name: 'App',
  props: {
    graphType: {
      type: String
    },
    graphData: {
      labels: [],
      datasets: [{data: []}]
    }
  },
  computed: {
    chartData() {
      let data = _.cloneDeep(this.graphData);
      data.datasets = [];
      data.datasets.push(
          {
            type: 'bar',
            label: '装備',
            backgroundColor: this.graphData.datasets[0].backgroundColor,
            data:this.graphData.datasets[0].data,
            fill: false,
            tension: 0,
            yAxisID: 'y-axis-1'
          }
      );
      data.datasets.push(
          {
            type: 'bar',
            label: '素材',
            backgroundColor: '#223044',
            data:this.graphData.datasets[0].data_consumption,
            fill: false,
            tension: 0,
            yAxisID: 'y-axis-1'
          }
      );

      return data
    },
    chartOptions() {
      return this.options
    }
  },
  watch: {},
  data() {
    return {
      options: {
        responsive: true,
        onClick: this.barClick,
        scales: {
          yAxes: [
            {
              id: "y-axis-1",
              position: "left"
            },
            {
              id: "y-axis-2",
              position: "right"
            }
          ]
        }
      }
    }
  },
  mounted() {

    this.initData();
  },
  methods: {
    /*************************************************
     * 画面アクション処理
     *************************************************/
    barClick: function (point, event) {
      if (event[0]) {
        this.selectBar(event[0].index);
      } else {
        this.hideDetailBar();
      }

    },
    /*************************************************
     * 初期処理
     *************************************************/
    initData: function () {
    },
    /*************************************************
     * データ連携
     *************************************************/
    selectBar: function (index) {
      this.$emit('selectBar', this.graphType, index)
    },
    hideDetailBar: function () {
      this.$emit('hideDetailBar', this.graphType);
    }
  }
}
</script>