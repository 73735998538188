import * as moment from 'moment';
import * as Model from './base';
import {MOMENT_FORMAT_PLAIN_SHORT} from "./base";

export class Item extends Model.modelItemBase {
    constructor(params = {}) {
        super();

        this.image_mode = 'min';

        this.item_id = 0;
        this.title = '';
        this.title_en = '';
        this.img_logo = '';
        this.item_type = 0;
        this.rarity = 0;
        this.parts_type = 0;
        this.maximum_number = 0;
        this.jobs = 0;
        this.potential_effect = '';
        this.equipability_level = 0;

        this.status = {
            hp: 0,
            mp: 0,
            attack: 0,
            defense: 0,
            magic_attack: 0,
            atk_spd: 0,
            str: 0,
            vit: 0,
            agi: 0,
            inte: 0,
            dex: 0,
            mnd: 0
        };

        this.amano = false;

    }

    static fromJson(params, detail) {
        let out = (detail) ? detail : new Item();

        out.item_id = parseInt(params.item_id, 10);
        out.title = params.title;
        out.title_en = params.title_en;
        if (out.image_mode === 'min') {
            out.img_logo = './nft_images_min/' + params.img_logo.replace(/png|gif/g, 'jpg');
        } else {
            out.img_logo = './nft_images/' + params.img_logo;
        }
        out.item_type = parseInt(params.item_type, 10);
        out.rarity = parseInt(params.rarity, 10);
        out.parts_type = parseInt(params.parts_type, 10);
        out.maximum_number = parseInt(params.maximum_number, 10);
        out.jobs = parseInt(params.jobs, 10);
        out.potential_effect = params.potential_effect;
        out.equipability_level = parseInt(params.equipability_level, 10);

        out.status.hp = parseInt(params.status.hp, 10);
        out.status.mp = parseInt(params.status.mp, 10);
        out.status.attack = parseInt(params.status.attack, 10);
        out.status.defense = parseInt(params.status.defense, 10);
        out.status.magic_attack = parseInt(params.status.magic_attack, 10);
        out.status.atk_spd = parseInt(params.status.atk_spd, 10);
        out.status.str = parseInt(params.status.str, 10);
        out.status.vit = parseInt(params.status.vit, 10);
        out.status.agi = parseInt(params.status.agi, 10);
        out.status.inte = parseInt(params.status.inte, 10);
        out.status.dex = parseInt(params.status.dex, 10);
        out.status.mnd = parseInt(params.status.mnd, 10);


        if (
            (parseInt(params.item_id, 10) >= 88000200000 && 88000200009 >= parseInt(params.item_id, 10)) ||
            (parseInt(params.item_id, 10) >= 88000210000 && 88000210009 >= parseInt(params.item_id, 10))
        ) {
            out.amano = true;
        }
        return out;
    }

}

export class Items extends Model.modelBase {
    constructor(Ranks = []) {
        super(Ranks);
    }

    static fromJson(params = {}) {
        let items = [];
        params.forEach((item) => {
            items.push(Item.fromJson(item));
        });

        let out = new Items(items);
        return out;
    }

}
