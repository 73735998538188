<template>
  <div id="contents" class="content is-flex-auto-vertical is-liner">

    <div id="side" class="fix" style="flex-basis:60px;min-width:60px;">
      <navbar
          :config="config"
          @setAuth="setAuth"></navbar>
    </div>

    <div id="main-content" class="grow is-flex is-vertical">

      <div id="pageMenu" class="fix is-flex-auto-vertical is-liner f_left">
        <div class="navi-title is-flex is-liner f_left f_vcenter">
          <h1 class="grow site-title has-pad-l20">GK Market Analyzer</h1>
        </div>

        <div class="spacer grow is-flex is-liner f_left f_vcenter"></div>
      </div>


      <div id="content" class="is-flex is-vertical">

        <h2>残高チェッカー</h2>

        <div class="balance-checker">

          <div class="is-flex is-liner">
            <div class="fix is-flex is-vertical has-pad-20" style="flex-basis:50%; min-width:50%;">
              <div class="is-flex is-liner balance-line">
                <div class="fix" style="flex-basis:150px;">APIキー</div>
                <div class="grow">
                  <input type="text" class="input" v-model="apiKey">
                </div>

              </div>

              <!--
              <div class="is-flex is-liner balance-line">
                <div class="fix" style="flex-basis:150px;">対象</div>
                <div class="grow">
                  <input type="text" class="input" v-model="targetAddress">
                </div>
              </div>
              -->

              <div class="is-flex is-liner balance-line">
                <div class="fix" style="flex-basis:150px;">&nbsp;</div>
                <div class="grow is-flex is-liner f_right">
                  <button class="button is-info has-margin-r10"
                          v-bind:disabled="canExec===false || status.monitoring===true"
                          v-on:click="checkExecute()">１度だけ実行
                  </button>
                  <button class="button is-info has-margin-r10"
                          v-bind:disabled="canMonitoring===false"
                          v-on:click="batchExecute()" v-text="(status.monitoring===false)?'監視（３秒毎）':'監視中'">
                  </button>
                  <button class="button is-danger"
                          v-bind:disabled="canStop===false"
                          v-on:click="batchStop()">停止
                  </button>
                </div>

              </div>


              <div class="is-flex is-liner balance-line" style="margin-top:50px;">
                <div class="fix" style="flex-basis:150px;">残高（ROND）</div>
                <div class="grow">
                  <div class="tag resultBalance is-flex f_right">
                    <span v-text="balance.toLocaleString()"></span>
                  </div>
                </div>

              </div>


            </div>
            <div class="fix is-flex is-vertical has-pad-20" style="flex-basis:50%; min-width:50%;">

              <div class="is-flex is-liner balance-line">
                <div class="grow is-flex is-liner">
                  <div class="grow has-pad-r10">
                    <div class="tag resultBalance is-flex f_center">
                      <span v-text="dt"></span>
                    </div>
                  </div>
                  <div class="fix" style="flex-basis:100px;min-width:100px;">
                    <div class="tag resultBalance is-flex f_center">
                      <span v-text="cnt"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="balance-line">
                <textarea class="textarea checkResult" v-model="resultData" readonly></textarea>

              </div>


            </div>

          </div>

          <div>
            <div>※事前に <a href="https://polygonscan.com/apis" target="_blank">「PolygonScan APIs」</a> のAPIキー発行が必要です。</div>
          </div>


        </div>


      </div>

    </div>

  </div>
</template>

<script>
import {commonFunc} from '@/models/commonFunc'
import navbar from "./common/NaviHeader.vue";
import axios from "axios";

import {Conf} from "@/models/conf";

export default {
  components: {
    'navbar': navbar,
  },
  name: 'balance',
  props: {
    config: new Conf()
  },
  data() {
    return {
      apiUrl: 'https://api.polygonscan.com/api',
      params: [
        {key: 'module', value: 'account'},
        {key: 'action', value: 'tokenbalance'},
        {key: 'contractaddress', value: '0x204820B6e6FEae805e376D2C6837446186e57981'},
        {key: 'address', value: ''},
        {key: 'tag', value: 'pending'},
        {key: 'apikey', value: ''}
      ],
      tm: undefined,
      cnt: 0,
      balance: 0,
      dt: '----',
      apiKey: '',
      targetAddress: '0x18EBDB05963E562921fdd24420c8cF27dbaa1F3E',
      resultData: '',
      checkDateTime: '',
      status: {
        monitoring: false
      }
    }
  },
  computed: {
    // 追加可否判定
    canExec: function () {
      return this.apiKey.length >= 34 && this.apiKey !== '' && this.targetAddress !== ''
    },
    canMonitoring: function () {
      return this.canExec === true && this.status.monitoring === false;
    },
    canStop: function () {
      return this.canExec === true && this.status.monitoring === true;
    },
  },
  created() {
  },
  mounted() {
    if (this.config.auth && this.config.auth > 0) {
      //処理なし
    }else{
      this.$router.push('/');
    }
  },
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/
    checkExecute: function () {
      return new Promise((resolve, reject) => {
        let self = this;
        let url = this.makeApiUrl();
        this.callApi(url)
            .then(function (res) {
              self.resultData = JSON.stringify(res, null, "\t");
              if (res.status && parseInt(res.status, 10) === 1) {
                self.balance = res.result / 1000000000000000000;
                self.dt = commonFunc.getNow();
              }
              self.cnt++;
            })
        resolve();
      });
    },
    batchExecute: function () {
      this.status.monitoring = true;
      this.tm = setInterval(this.checkExecute, 3000);
    },
    batchStop: function () {
      this.status.monitoring = false;
      clearInterval(this.tm);
    },

    /*************************************************
     * 算出処理
     *************************************************/
    makeApiUrl: function () {
      let self = this;
      let params = this.params.map(function (p) {
        let buf = '';
        switch (p.key) {
          case 'address':
            buf = p.key + '=' + self.targetAddress;
            break;
          case 'apikey':
            buf = p.key + '=' + self.apiKey;
            break;
          default:
            buf = p.key + '=' + p.value;
        }
        return buf;
      })
      return this.apiUrl + '?' + params.join('&')+'&ts='+commonFunc.getTs();

    },

    /*************************************************
     * 初期処理
     *************************************************/
    callApi: function (url) {
      return axios.get(url)
          .then(function (response) {
            if (response.data) {
              self.jsonData = response.data;
            }
            return response.data;
          })
          .catch(error => {
            return {status: '9', messages: '通信エラー'};
          });
    },


    /*************************************************
     * コンポーネント連携
     *************************************************/
    setAuth: function (auth) {
      this.$emit('setAuth',auth);
    }


  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>
