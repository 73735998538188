<template>
  <div id="contents" class="content is-flex is-liner">

    <!--
    <div id="side" class="fix" style="flex-basis:60px;min-width:60px;">
      <navbar></navbar>
    </div>
    -->

    <div id="main-content" class="grow is-flex is-vertical">

      <div id="content" class="is-flex is-vertical" v-if="status.loadData===true">

        <h2 v-text="setTitle()"></h2>

        <div class="item-detail has-pad-t20">

          <div class="card item-card">

            <div class="card-content">

              <div id="searchBox" class="is-flex is-liner" style="padding:10px;">
                <div class="fix is-flex is-liner f_left f_vcenter" style="flex-basis:50px;min-width:50px;color: white;">期間</div>
                <div class="fix" style="flex-basis:150px;min-width:150px;">
                  <VueDatePicker
                      v-model="search.from"
                      format="yyyy/MM/dd"
                      model-type="yyyy-MM-dd"
                      auto-apply
                      locale="ja"
                      :enable-time-picker="false"/>
                </div>
                <div class="fix is-flex is-liner f_center f_vcenter" style="flex-basis:40px;min-width:40px;color: white;">～</div>
                <div class="fix" style="flex-basis:150px;min-width:150px;">
                  <VueDatePicker v-model="search.to"
                                 format="yyyy/MM/dd"
                                 model-type="yyyy-MM-dd"
                                 auto-apply
                                 locale="ja"
                                 :enable-time-picker="false"/>
                </div>

                <div class="grow has-pad-l20 is-flex is-liner f_left f_vcenter" style="color: white;">※複数回取引のある同一NFTの場合、各パラメータは最新の取引時点の値が表示されています</div>

              </div>


              <div class="item-list-with-thumbnail is-flex is-vertical" style="padding-top:0;">

                <div id="item-list-header" class="item-list-header" v-on:scroll="scrollItemList($event, 'header')" style="overflow-x:hidden;overflow-y:hidden;">

                  <div class="status-parm-header is-flex is-liner">

                    <div class="status-parm-header-rap grow is-flex is-liner f_right">
                      <table id="trade-list-head" class="table is-bordered">
                        <thead>
                        <tr>
                          <th style="width:9%;">取引日時</th>
                          <th style="width:8%;">取引額<br>(USDT)</th>
                          <th style="width:3%;">LV</th>
                          <th style="width:5%;">HP</th>
                          <th style="width:5%;">MP</th>
                          <th style="width:5%;">物攻</th>
                          <th style="width:5%;">防御</th>
                          <th style="width:5%;">魔攻</th>
                          <th style="width:4%;">攻速</th>
                          <th style="width:4%;">腕力</th>
                          <th style="width:4%;">体力</th>
                          <th style="width:4%;">速さ</th>
                          <th style="width:4%;">知力</th>
                          <th style="width:4%;">器用</th>
                          <th style="width:4%;">精神</th>
                          <th style="width:3%;">物理<br>ｸﾘ値</th>
                          <th style="width:3%;">物理<br>ｸﾘ率</th>
                          <th style="width:3%;">魔法<br>ｸﾘ値</th>
                          <th style="width:3%;">魔法<br>ｸﾘ率</th>
                          <th style="width:3%;">詠唱<br>速度</th>
                          <th style="width:3%;">防御<br>効率</th>
                          <th style="width:3%;">ｶﾞｰﾄﾞ<br>値</th>
                          <th style="width:3%;">ｶﾞｰﾄﾞ<br>効果</th>
                          <th style="width:3%;">ドロ率</th>
                        </tr>
                        </thead>
                      </table>

                    </div>
                    <div class="status-parm-header-rap">
                      <div class="fix" style="flex-basis:18px;min-width:18px">&nbsp;</div>
                    </div>
                  </div>

                </div>

                <div id="item-list-body" class="grow item-list-body" v-on:scroll="scrollItemList($event, 'body')" style="overflow-x:hidden;">

                  <div class="status-parm-rap">

                    <table id="trade-list-body" class="table is-bordered">
                      <tbody>
                      <template v-for="item in lists.items" :key="item.id">
                      <tr>
                        <td style="width:9%;"><span v-text="item.history_date"></span></td>
                        <td style="width:8%;"><span v-text="item.amount_usdt"></span></td>
                        <td style="width:3%;"><span v-text="item.level"></span></td>
                        <td style="width:5%;"><span v-text="item.hp"></span></td>
                        <td style="width:5%;"><span v-text="item.mp"></span></td>
                        <td style="width:5%;"><span v-text="item.attack"></span></td>
                        <td style="width:5%;"><span v-text="item.defense"></span></td>
                        <td style="width:5%;"><span v-text="item.magic_attack"></span></td>
                        <td style="width:4%;"><span v-text="item.atk_spd"></span></td>
                        <td style="width:4%;"><span v-text="item.str"></span></td>
                        <td style="width:4%;"><span v-text="item.vit"></span></td>
                        <td style="width:4%;"><span v-text="item.agi"></span></td>
                        <td style="width:4%;"><span v-text="item.inte"></span></td>
                        <td style="width:4%;"><span v-text="item.dex"></span></td>
                        <td style="width:4%;"><span v-text="item.mnd"></span></td>
                        <td style="width:3%;"><span v-text="item.physical_cri"></span></td>
                        <td style="width:3%;"><span v-text="item.physical_cri_multi"></span></td>
                        <td style="width:3%;"><span v-text="item.magic_cri"></span></td>
                        <td style="width:3%;"><span v-text="item.magic_cri_multi"></span></td>
                        <td style="width:3%;"><span v-text="item.cast_spd"></span></td>
                        <td style="width:3%;"><span v-text="item.def_proficiency"></span></td>
                        <td style="width:3%;"><span v-text="item.guard"></span></td>
                        <td style="width:3%;"><span v-text="item.guard_effect"></span></td>
                        <td style="width:3%;"><span v-text="item.item_drop_rate"></span></td>
                      </tr>
                      </template>
                      </tbody>
                    </table>


                  </div>

                </div>


              </div>






            </div>

          </div>


        </div>


      </div>


    </div>

  </div>
</template>

<script>
import {commonFunc} from '@/models/commonFunc'
import axios from "axios";
import {Trades, Trade} from "@/models/trade";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


import * as _ from "lodash";

export default {
  components: {
    'VueDatePicker': VueDatePicker,
  },
  name: 'item',
  data() {
    return {
      mode: 'base', //base, cosplay
      elemSize: {
        base: {
          widthApp: 0,
          heightApp: 0,
        },
        listBody: {
          width: 0,
          height: 650,
        }
      },
      id: 0,
      trades: new Trades(),
      lists: new Trades(),
      search: {
        from: commonFunc.getMonthAgo(1),
        to: commonFunc.getToday()
      },
      scroll: {
        x: 0
      },
      status: {
        loadData: false,
        resize: false
      }
    }
  },
  computed: {
    canBack: function () {
      return this.item.amano === true && this.item.setup_logo.length > 0 && this.modalImgIndex > 0;
    },
    canNext: function () {
      return this.item.amano === true && this.item.setup_logo.length > 0 && (this.modalImgIndex < (this.item.setup_logo.length - 1));
    }
  },
  created() {
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id, 10);
    }
    if (this.id > 0) {
      this.initData()
          .then(this.filterList);
    }
    this.calculateWindowWidth();
    this.setResizeFlg(true);
    window.addEventListener('resize', this.calculateWindowWidth);

  },
  watch: {
    'search.from': function (to) {
      this.filterList();
    },
    'search.to': function (to) {
      this.filterList();
    },
    'status.resize': function (to) {
      if (to === true) {
        /* height
        #content.padding:40px(20x2)
        #item-list-title-header: 51px;
        #padding: 20px;
        #item-list-header: 45px;
        #searchBox.height:可変
        */

        //let widthApp = document.getElementById('app').clientWidth;

        let spacer_height = 20;

        let searchBox = document.getElementById('searchBox');
        let searchBoxHeight = 58;
        if(searchBox){
          searchBoxHeight = document.getElementById('searchBox').clientHeight;
        }

        let targetElem;
        this.elemSize.listBody.height = this.elemSize.base.heightApp - (156 + spacer_height + searchBoxHeight);

        targetElem = document.getElementById('item-list-body');
        if(targetElem){
          targetElem.style.height = this.elemSize.listBody.height + "px";
        }
        this.setResizeFlg(false);
      }
    },
  },
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/
    scrollItemList: function (e, type) {
      if (this.scroll.x !== e.target.scrollLeft) {
        this.scroll.x = e.target.scrollLeft;
        if (type === 'header') {
          document.getElementById('item-list-body').scrollLeft = this.scroll.x;
        } else if (type === 'body') {
          document.getElementById('item-list-header').scrollLeft = this.scroll.x;
        }
      }
    },

    /*************************************************
     * 汎用処理
     *************************************************/
    setResizeFlg: function (val) {
      this.status.resize = val;
    },

    /*************************************************
     * 算出
     *************************************************/
    calculateWindowWidth: function () {
      let heightApp = document.getElementById('app').clientHeight;
      let widthApp = document.getElementById('app').clientWidth;
      if (this.elemSize.base.heightApp !== heightApp || this.elemSize.base.widthApp !== widthApp) {
        this.elemSize.base.heightApp = heightApp;
        this.elemSize.base.widthApp = widthApp;
        this.setResizeFlg(true);
      }
    },
    setTitle: function () {
      return 'ID: ' + this.id;
    },
    filterList: function () {
      return new Promise((resolve, reject) => {
        this.lists.items = [];
        let self = this;
        if (this.trades.items.length > 0) {
          this.lists.items = this.trades.items.filter(function (trade) {
            return ((self.search.from).replace(/\//,'-') + ' 00:00:00') <= trade.history_date && trade.history_date <= ((self.search.to).replace(/\//,'-') + ' 23:59:59');
          });
        }
        self.status.loadData = true;
        resolve();
      });
    },

    /*************************************************
     * 動的クラス設定
     *************************************************/


    /*************************************************
     * 初期処理
     *************************************************/


    initData: function () {
      return new Promise((resolve, reject) => {
        let self = this;
        axios.get('./json/trade/' + this.id + '.json')
            .then(function (response) {
              if (response.data && response.data.history) {
                self.trades = Trades.fromJson(response.data.history);
              }
              resolve();
            });
      });
    }


  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>
