import * as moment from 'moment';

export const MOMENT_FORMAT_DISPLAY = 'YYYY/MM/DD HH:mm:ss';
export const MOMENT_FORMAT_DISPLAY_TS = 'YYYYMMDDHHmmss';

export class commonFunc {
    constructor() {

    }

    static getNow() {
        return moment().format(MOMENT_FORMAT_DISPLAY);
    }

    static getTs() {
        return moment().format(MOMENT_FORMAT_DISPLAY_TS);
    }

    static getItemTypeName(rarity) {
        let resultText = '';
        switch (parseInt(rarity, 10)) {
            case 0:
                resultText = 'ベース装備';
                break;
            case 1:
                resultText = 'オシャレ装備';
                break;
            default:
                resultText = '？？？';
                break;
        }
        return resultText;
    }

    static getRankName(rarity) {
        let resultText = '';
        switch (parseInt(rarity, 10)) {
            case 0:
                resultText = '--';
                break;
            case 1:
                resultText = 'N';
                break;
            case 2:
                resultText = 'R';
                break;
            case 4:
                resultText = 'SR';
                break;
            case 8:
                resultText = 'LR';
                break;
            default:
                resultText = '？';
                break;
        }
        return resultText;
    }

    static getPartsName(parts_type) {
        let resultText = '';
        switch (parseInt(parts_type, 10)) {
            case 0:
                resultText = '？？';
                break;
            case 1:
                resultText = '右手';
                break;
            case 2:
                resultText = '左手';
                break;
            case 4:
                resultText = '頭';
                break;
            case 8:
                resultText = '体';
                break;
            case 16:
                resultText = '足';
                break;
            case 32:
                resultText = '肩';
                break;
            case 64:
                resultText = '背';
                break;
            case 128:
                resultText = 'SET';
                break;
            case 256:
                resultText = '指輪';
                break;
        }
        return resultText;
    }


    static getConsumptionItemTypeName(parts_type) {
        let resultText = '';
        switch (parseInt(parts_type, 10)) {
            case 0:
                resultText = '？？';
                break;
            case 1:
                resultText = 'コイン';
                break;
            case 2:
                resultText = '強化素材';
                break;
            case 4:
                resultText = '消耗品';
                break;
            case 8:
                resultText = '食料';
                break;
            case 16:
                resultText = '一般素材';
                break;
            case 32:
                resultText = '投票券';
                break;
        }
        return resultText;
    }

    static hankaku2Zenkaku(str) {
        if (!str) {
            return '';
        }
        return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
    }

    static zenkaku2Hankaku(str) {
        if (!str) {
            return '';
        }
        return str.replace(/[A-Za-z0-9]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) + 0xFEE0);
        });
    }

    static hiragana2Katakana(str) {
        if (!str) {
            return '';
        }
        return str.replace(/[ぁ-ん]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) + 0x60);
        });
    }

    static katakana2Hiragana(str) {
        if (!str) {
            return '';
        }
        return str.replace(/[ァ-ン]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0x60);
        });
    }

    static sortList(col, list) {
        let cols = col.split('.')
        list.sort((a, b) => {
            if(cols.length===1){
                if (a[col] < b[col]) return -1;
                if (a[col] > b[col]) return 1;
            }else{
                if (a[cols[0]][cols[1]] < b[cols[0]][cols[1]]) return -1;
                if (a[cols[0]][cols[1]] > b[cols[0]][cols[1]]) return 1;
            }

            return 0;
        });
        return list;
    }

    static sortListReverse(col, list) {
        let cols = col.split('.')
        list.sort((b, a) => {
            if(cols.length===1){
                if (a[col] < b[col]) return -1;
                if (a[col] > b[col]) return 1;
            }else{
                if (a[cols[0]][cols[1]] < b[cols[0]][cols[1]]) return -1;
                if (a[cols[0]][cols[1]] > b[cols[0]][cols[1]]) return 1;
            }

            return 0;
        });
        return list;
    }

    static getToday(){
        return moment().format('YYYY-MM-DD');
    }
    static getMonthAgo(month){
        return moment().subtract(month, 'months').format('YYYY-MM-DD');
    }
}