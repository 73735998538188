import * as moment from 'moment';
import * as Model from './base';
import {MOMENT_FORMAT_PLAIN_SHORT} from "./base";

export class RankGroup extends Model.modelItemBase {
    constructor(params = {}) {
        super();

        this.image_mode = 'min';

        this.total = 0;
        this.cnt = 0;
        this.item_id = 0;
        this.dt_from = '';
        this.dt_to = '';
        this.img_logo = '';
        this.title = '';
        this.title_en = '';

    }

    static fromJson(params) {
        let out = new RankGroup();

        if (params.total) {
            out.total = params.total;
        }
        out.cnt = params.cnt;
        out.item_id = params.item_id;
        out.dt_from = moment(params.dt_from).format(MOMENT_FORMAT_PLAIN_SHORT);
        out.dt_to = moment(params.dt_to).format(MOMENT_FORMAT_PLAIN_SHORT);
        if (out.image_mode === 'min') {
            out.img_logo = './nft_images_min/' + params.img_logo.replace(/png|gif/g, 'jpg');
        } else {
            out.img_logo = './nft_images/' + params.img_logo;
        }
        out.title = params.title;
        out.title_en = params.title_en;

        return out;
    }

}

export class RankGroups extends Model.modelBase {
    constructor(Ranks = []) {
        super(Ranks);
    }

    static fromJson(params = {}) {
        let items = [];
        params.forEach((item) => {
            items.push(RankGroup.fromJson(item));
        });

        let out = new RankGroups(items);
        return out;
    }

}
