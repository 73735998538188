<template>
  <router-view
      :config="config"
      @setAuth="setAuth"/>
</template>

<script>
import {Conf} from "@/models/conf";

export default {
  data() {
    return {
      config: new Conf()
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    setAuth: function (auth) {
      this.config.auth = auth;
    }
  }
}
</script>