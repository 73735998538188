import * as moment from 'moment';
import * as Model from './base';
import {MOMENT_FORMAT_PLAIN_SHORT} from "./base";

export class RankSingle extends Model.modelItemBase {
    constructor(params = {}) {
        super();

        this.image_mode = 'min';

        this.index = 0;
        this.total = 0;
        this.nft_id = 0;
        this.item_id = 0;
        this.dt = '';
        this.img_logo = '';
        this.title = '';
        this.title_en = '';

    }

    static fromJson(params, index) {
        let out = new RankSingle();

        out.index = index;
        out.total = params.total;
        out.nft_id = params.nft_id;
        out.item_id = params.item_id;
        //out.dt = params.dt;
        out.dt = moment(params.dt).format(MOMENT_FORMAT_PLAIN_SHORT);
        if (out.image_mode === 'min') {
            out.img_logo = './nft_images_min/' + params.img_logo.replace(/png|gif/g, 'jpg');
        } else {
            out.img_logo = './nft_images/' + params.img_logo;
        }
        out.title = params.title;
        out.title_en = params.title_en;

        return out;
    }

}

export class RankSingles extends Model.modelBase {
    constructor(Ranks = []) {
        super(Ranks);
    }

    static fromJson(params = {}) {
        let items = [];
        params.amount.forEach((item, index) => {
            items.push(RankSingle.fromJson(item, index));
        });

        let out = new RankSingles(items);
        return out;
    }

}
