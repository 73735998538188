import * as moment from 'moment';
import * as Model from './base';

export class Conf extends Model.modelItemBase {
    constructor(params = {}) {
        // _inputErrors
        super();

        this.auth = 0;

    }

}
