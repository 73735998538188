<template>
  <Pie :data="chartData" :options="chartOptions"/>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import {Pie} from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend)

export default {
  components: {Pie},
  name: 'App',
  props: {
    graphType: {
      type: String
    },
    graphData: {
      labels: [],
      datasets: [{data: []}]
    }
  },
  computed: {
    chartData() {
      return this.graphData
    },
    chartOptions() {
      return this.options
    }
  },
  watch: {

  },
  data() {
    return {
      options: {
        responsive: true
      }
    }
  },
  mounted() {

    this.initData();
  },
  methods: {
    /*************************************************
     * 画面アクション処理
     *************************************************/

    /*************************************************
     * 初期処理
     *************************************************/
    initData: function () {
    },
    /*************************************************
     * データ連携
     *************************************************/

  }
}
</script>