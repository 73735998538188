//import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import TopComponent from "@/components/Top.vue";
import RankingComponent from "@/components/Ranking.vue";
import ItemComponent from "@/components/Item.vue";
import DetailComponent from "@/components/Detail.vue";
import TradeComponent from "@/components/Trade.vue";

import ConsumptionComponent from "@/components/Consumption.vue";
import DetailConsumptionComponent from "@/components/DetailConsumption.vue";

import WinningComponent from "@/components/Winning.vue";
import BlanceCheckComponent from "@/components/BalanceCheck.vue";
import CommentComponent from "@/components/Comment.vue";
import HelpComponent from "@/components/Help.vue";

const routes = [
  {
    path: '/',
    name: 'top',
    component: TopComponent,
    meta:{title:''}
  },
  {
    path: '/ranking',
    name: 'ranking',
    component: RankingComponent,
    meta:{title:''}
  },
  {
    path: '/item',
    name: 'item',
    component: ItemComponent,
    meta:{title:''}
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: DetailComponent,
    meta:{title:''}
  },
  {
    path: '/trade/:id',
    name: 'trade',
    component: TradeComponent,
    meta:{title:''}
  },
  {
    path: '/consumption',
    name: 'consumption',
    component: ConsumptionComponent,
    meta:{title:''}
  },
  {
    path: '/detail-consumption/:id',
    name: 'detail-consumption',
    component: DetailConsumptionComponent,
    meta:{title:''}
  },
  {
    path: '/winning',
    name: 'winning',
    component: WinningComponent,
    meta:{title:''}
  },
  {
    path: '/balance',
    name: 'balance',
    component: BlanceCheckComponent,
    meta:{title:''}
  },
  {
    path: '/comment',
    name: 'comment',
    component: CommentComponent,
    meta:{title:''}
  },
  {
    path: '/help',
    name: 'help',
    component: HelpComponent,
    meta:{title:''}
  },

]

const router = createRouter({
//  history: createWebHistory(),
  history: createWebHashHistory(),
  routes
})

export default router
