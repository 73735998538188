<template>
  <div id="contents" class="content is-flex-auto-vertical is-liner">

    <div id="side" class="fix" style="flex-basis:60px;min-width:60px;">
      <navbar
          :config="config"
          :ts="ts"
          @setAuth="setAuth"></navbar>
    </div>

    <div id="main-content" class="grow is-flex is-vertical">

      <div id="pageMenu" class="fix is-flex-auto-vertical is-liner f_left">

        <div class="navi-title is-flex is-liner f_left f_vcenter">
          <h1 class="grow site-title has-pad-l20">GK Market Analyzer</h1>
        </div>

        <button class="navi-box"
                v-on:click="setMode('all')"
                v-bind:class="isActiveMode('all')">月毎
        </button>
        <button class="navi-box"
                v-on:click="setMode('week')"
                v-bind:class="isActiveMode('week')">週毎
        </button>
        <button class="navi-box"
                v-on:click="setMode('day')"
                v-bind:class="isActiveMode('day')">日毎
        </button>

        <div class="spacer grow is-flex is-liner f_left f_vcenter"></div>

      </div>

      <div id="content" class="is-flex is-vertical">

        <h2>取引状況<span v-text="setDataTsStr()"></span></h2>

        <div class="is-flex-auto-vertical-middle is-liner has-flex-rap">

          <div class="graph fix has-min-width-half has-min-basis-half">

            <div class="card">
              <div class="card-content">
                <div class="content">
                  <barGraph
                      :graph-type="'volume'"
                      :graph-data="setBarGraphData(graph_volume,'取引件数',colors.volumeBarColor)"
                      @selectBar="selectBar"
                      @hideDetailBar="hideDetailBar">
                  </barGraph>
                </div>
              </div>
            </div>

          </div>

          <div class="graph fix has-min-width-half has-min-basis-half">


            <div class="card">
              <div class="card-content">
                <div class="content">
                  <barGraph
                      :graph-type="'amount'"
                      :graph-data="setBarGraphData(graph_amount,'USDT',colors.amountBarColor)"
                      @selectBar="selectBar"
                      @hideDetailBar="hideDetailBar">
                  </barGraph>
                </div>
              </div>
            </div>

          </div>

        </div>


        <div class="is-flex-auto-vertical-middle is-liner" v-if="status.detailChart===true">

          <div class="graph fix has-min-width-half has-min-basis-half" style="flex-basis:50%;">

            <div class="card">
              <div class="card-content">
                <div class="content">
                  <pieGraph
                      :style="{'max-height':`300px`}"
                      :graph-type="'volume'"
                      :graph-data="setPieGraphData(detail_volume,detailTitleVolume)"
                      @selectBar="selectBar"
                      @hideDetailBar="hideDetailBar">
                  </pieGraph>
                </div>
              </div>
            </div>

          </div>

          <div class="graph fix has-min-width-half has-min-basis-half" style="flex-basis:50%;">


            <div class="card">
              <div class="card-content">
                <div class="content">
                  <pieGraph
                      :style="{'max-height':`300px`}"
                      :graph-type="'amount'"
                      :graph-data="setPieGraphData(detail_amount,detailTitleAmount)"
                      @selectBar="selectBar"
                      @hideDetailBar="hideDetailBar">
                  </pieGraph>
                </div>
              </div>
            </div>

          </div>

        </div>


        <div class="has-width-full has-pad-t10" v-if="mode==='all'">

          <h2 class="has-width-full">レート<span>（MV/USDT：KuCoin）</span></h2>

          <div class="chart is-flex is-liner">

            <div class="grow">

              <div class="card">
                <div class="card-content">
                  <div class="content">
                    <lineGraph
                        :graph-data="setLineGraphData(mv,'MV/USDT',colors.mainLineColor)">
                    </lineGraph>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>




      </div>


    </div>

  </div>
</template>

<script>
import navbar from "./common/NaviHeader.vue";

import barGraph from './common/BarGraphDouble.vue'
import lineGraph from './common/LineGraph.vue'
import pieGraph from './common/PieGraph.vue'

import {Conf} from "@/models/conf";
import {Graph} from "@/models/graph";
import {Mv} from "@/models/mv";
import axios from 'axios';

import * as _ from "lodash";
import {Items} from "@/models/item";

export default {
  components: {
    'navbar': navbar,
    'barGraph': barGraph,
    'lineGraph': lineGraph,
    'pieGraph': pieGraph,
  },
  name: 'top',
  props: {
    config: new Conf()
  },
  data() {
    return {
      mode: 'all', //all, week
      jsonData: {
        ts:'',
        graph: {
          all:{},
          week:{},
          day:{}
        },
        mv: new Items()
      },
      ts:'',
      graph_volume: new Graph(),
      graph_amount: new Graph(),
      detail_volume: new Graph(),
      detail_amount: new Graph(),
      detailTitleVolume: '',
      detailTitleAmount: '',
      mv: new Mv(),
      colors: {
        volumeBarColor: '#40a650',
        amountBarColor: '#b74747',
        mainLineColor: '#666666',
      },
      status: {
        detailChart: false
      }
    }
  },
  created() {
  },
  mounted() {
    this.firstLoad()
        .then(this.initData);
  },
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/
    setMode: function (mode) {
      this.mode = mode;
      this.initData();
    },
    selectBar: function (graphType, index) {
      let scales = ['N', 'R', 'SR', 'LR','素材'];
      let label = this.graph_volume.scale[index];

      //取引件数
      this.detailTitleVolume = '内訳/取引件数（' + label + '）';
      this.detail_volume.scale = _.cloneDeep(scales);
      this.detail_volume.all = [];
      this.detail_volume.all.push(this.graph_volume.normal[index].total);
      this.detail_volume.all.push(this.graph_volume.rare[index].total);
      this.detail_volume.all.push(this.graph_volume.super_rare[index].total);
      this.detail_volume.all.push(this.graph_volume.legend[index].total);
      this.detail_volume.all.push(this.graph_volume.consumption[index].total);

      this.detail_volume.scale[0] += ' [' + this.graph_volume.normal[index].total.toLocaleString() + ']';
      this.detail_volume.scale[1] += ' [' + this.graph_volume.rare[index].total.toLocaleString() + ']';
      this.detail_volume.scale[2] += ' [' + this.graph_volume.super_rare[index].total.toLocaleString() + ']';
      this.detail_volume.scale[3] += ' [' + this.graph_volume.legend[index].total.toLocaleString() + ']';
      this.detail_volume.scale[4] += ' [' + this.graph_volume.consumption[index].total.toLocaleString() + ']';

      //USDT
      this.detailTitleAmount = '内訳/USDT（' + label + '）';
      this.detail_amount.scale = _.cloneDeep(scales);
      this.detail_amount.all = [];

      let normal = Math.round(this.graph_amount.normal[index].total*1000)/1000;
      let rare = Math.round(this.graph_amount.rare[index].total*1000)/1000;
      let super_rare = Math.round(this.graph_amount.super_rare[index].total*1000)/1000;
      let legend = Math.round(this.graph_amount.legend[index].total*1000)/1000;
      let consumption = Math.round(this.graph_amount.consumption[index].total*1000)/1000;

      this.detail_amount.all.push(normal);
      this.detail_amount.all.push(rare);
      this.detail_amount.all.push(super_rare);
      this.detail_amount.all.push(legend);
      this.detail_amount.all.push(consumption);

      this.detail_amount.scale[0] += ' [ ' + normal.toLocaleString() + ' ]';
      this.detail_amount.scale[1] += ' [ ' + rare.toLocaleString() + ' ]';
      this.detail_amount.scale[2] += ' [ ' + super_rare.toLocaleString() + ' ]';
      this.detail_amount.scale[3] += ' [ ' + legend.toLocaleString() + ' ]';
      this.detail_amount.scale[4] += ' [ ' + consumption.toLocaleString() + ' ]';

      this.status.detailChart = true;
    },
    hideDetailBar: function (graphType) {
      this.status.detailChart = false;
    },

    /*************************************************
     * 汎用処理
     *************************************************/
    setBarGraphData: function (graph_data, label, fill_color = '') {
      let viewData = {label: label, data: graph_data.all, data_consumption: graph_data.all_consumption};
      if (fill_color) {
        viewData['backgroundColor'] = fill_color;
      }
      return {
        labels: graph_data.scale,
        datasets: [viewData]
      };
    },
    setLineGraphData: function (graph_data, label, fill_color = '') {
      let viewData = {label: label, data: graph_data.amount, tension: 0.1, fill: true};
      if (fill_color) {
        viewData['borderColor'] = fill_color;
      }
      return {
        labels: graph_data.scale,
        datasets: [viewData]
      };
    },
    setDataTsStr:function(){
      let tsStr='';
      if(this.ts!==''){
        tsStr = '（'+this.ts+' 時点）';
      }
      return tsStr;
    },


    setPieGraphData: function (graph_data, label) {
      let colors = [
        'rgb(164,164,164)',
        'rgb(64,164,221)',
        'rgb(113,39,192)',
        'rgb(255,168,0)',
        'rgb(34,48,68)',
      ]
      let viewData = {label: label, data: graph_data.all, backgroundColor: colors};

      return {
        labels: graph_data.scale,
        datasets: [viewData]
      };
    },

    /*************************************************
     * 動的クラス設定
     *************************************************/
    isActiveMode: function (mode) {
      let cname = {'is-active': false};

      if (this.mode === mode) {
        cname['is-active'] = true;
      }
      return cname;
    },

    /*************************************************
     * 初期処理
     *************************************************/
    initData: function () {
      return new Promise((resolve, reject) => {

        if(this.jsonData.ts){
          this.ts = this.jsonData.ts;
        }

        if (this.jsonData.graph[this.mode]) {

          if (this.jsonData.graph[this.mode].amount) {
            this.graph_amount = Graph.fromJson(this.jsonData.graph[this.mode].amount);
          }
          if (this.jsonData.graph[this.mode].volume) {
            this.graph_volume = Graph.fromJson(this.jsonData.graph[this.mode].volume);
          }
        }
        if (this.jsonData.mv) {
          this.mv = Mv.fromJson(this.jsonData.mv);
        }

      });

    },

    firstLoad: function () {
      return new Promise((resolve, reject) => {
        let self = this;
        axios.get('./json/top.json')
            .then(function (response) {
              if (response.data) {
                let data = response.data;
                if(data.ts){
                  self.jsonData.ts = data.ts;
                }
                if (data.graph) {
                  self.jsonData.graph = data.graph;
                }
                if (data.mv) {
                  self.jsonData.mv = data.mv;
                }
              }
              resolve();
            })
            .catch(function (error) {
              console.log(error)
            });
      });
    },

    /*************************************************
     * コンポーネント連携
     *************************************************/
    setAuth: function (auth) {
      this.$emit('setAuth',auth);
    }


  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>
