import * as moment from 'moment';
import * as Model from './base';

export class Graph extends Model.modelItemBase {
    constructor(params = {}) {
        // _inputErrors
        super();

        this.scale = [];
        this.consumption = [];
        this.normal = [];
        this.rare = [];
        this.super_rare = [];
        this.legend = [];
        this.all = [];
        this.all_consumption = [];

    }

    static fromJson(params) {
        let out = new Graph();

        if (params.scale.length > 0) {
            out.scale = params.scale;
        }
        if (params.consumption.length > 0) {
            out.consumption = params.consumption;
        }
        if (params.normal.length > 0) {
            out.normal = params.normal;
        }
        if (params.rare.length > 0) {
            out.rare = params.rare;
        }
        if (params.super_rare.length > 0) {
            out.super_rare = params.super_rare;
        }
        if (params.legend.length > 0) {
            out.legend = params.legend;
        }
        for (let i = 0; i < out.scale.length; i++) {
            out.all.push(out.normal[i].total + out.rare[i].total + out.super_rare[i].total + out.legend[i].total);
            out.all_consumption.push(out.consumption[i].total);
        }

        return out;
    }

}
