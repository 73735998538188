<template>
  <div id="contents" class="content is-flex is-liner">

    <!--
    <div id="side" class="fix" style="flex-basis:60px;min-width:60px;">
      <navbar></navbar>
    </div>
    -->

    <div id="main-content" class="grow is-flex is-vertical">

      <div id="content" class="is-flex is-vertical" v-if="status.loadData===true">

        <h2 v-text="setTitle()"></h2>

        <div class="item-detail has-pad-t20">

          <div class="card item-card">

            <div class="card-content">

              <div>

                <div class="is-flex-auto-vertical-wide is-liner">

                  <div class="item-detail-left is-flex is-vertical">

                    <div class="is-flex-auto-vertical is-liner">


                      <!-- サムネイル＋装備可能職 -->
                      <div class="fix has-pad-r20 has-pad-b20 is-flex is-vertical">

                        <img id="item-thumbnail" class="img-frame" v-bind:src="item.img_logo" alt="item.title_en">

                        <div class="table-rap has-pad-t10">
                          <table class="table is-bordered status-base is-hard-narrow">
                            <tbody>
                            <tr>
                              <th>ファイ</th>
                              <td class="is-center"><span v-text="getJobEnable(1,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>シーフ</th>
                              <td class="is-center"><span v-text="getJobEnable(2,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>クレ</th>
                              <td class="is-center"><span v-text="getJobEnable(4,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>ウィザ</th>
                              <td class="is-center"><span v-text="getJobEnable(8,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>ガデ</th>
                              <td class="is-center"><span v-text="getJobEnable(16,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>マジナイ</th>
                              <td class="is-center"><span v-text="getJobEnable(32,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>アサシン</th>
                              <td class="is-center"><span v-text="getJobEnable(64,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>ビショ</th>
                              <td class="is-center"><span v-text="getJobEnable(128,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>ウォロ</th>
                              <td class="is-center"><span v-text="getJobEnable(256,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>モンク</th>
                              <td class="is-center"><span v-text="getJobEnable(512,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>ランサー</th>
                              <td class="is-center"><span v-text="getJobEnable(1024,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>バスター</th>
                              <td class="is-center"><span v-text="getJobEnable(2048,item.jobs)"></span></td>
                            </tr>
                            <tr>
                              <th>セイバー</th>
                              <td class="is-center"><span v-text="getJobEnable(4096,item.jobs)"></span></td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <!-- 補足情報 -->
                      <div class="has-pad-r20 grow">
                        <div class="table-rap">
                          <table class="table is-bordered status-base is-semi-narrow">
                            <tbody>
                            <tr>
                              <th>種類</th>
                              <td><span v-text="getItemTypeName(item.item_type)"></span></td>
                            </tr>
                            <tr>
                              <th>ID</th>
                              <td><span v-text="item.item_id"></span></td>
                            </tr>
                            <tr>
                              <th>レアリティ</th>
                              <td><span v-text="getRankName(item.rarity)"></span></td>
                            </tr>
                            <tr>
                              <th>部位</th>
                              <td><span v-text="getPartsName(item.parts_type)"></span></td>
                            </tr>
                            <tr>
                              <th>装備可能LV</th>
                              <td><span v-text="item.equipability_level"></span></td>
                            </tr>
                            <tr>
                              <th>発行上限</th>
                              <td><span v-text="item.maximum_number"></span></td>
                            </tr>
                            <tr>
                              <th>取引回数</th>
                              <td><span v-text="item.statistics.count"></span></td>
                            </tr>
                            <tr>
                              <th>取引総額</th>
                              <td><span v-text="setAmount(item.statistics.amount_total,'USDT')"></span></td>
                            </tr>
                            <tr>
                              <th>高値</th>
                              <td><span
                                  v-text="setAmount(item.statistics.amount_max,'USDT', item.statistics.amount_max_date)"></span>
                              </td>
                            </tr>
                            <tr>
                              <th>底値</th>
                              <td><span
                                  v-text="setAmount(item.statistics.amount_min,'USDT', item.statistics.amount_min_date)"></span>
                              </td>
                            </tr>
                            <tr>
                              <th>平均（直近１月）</th>
                              <td><span v-text="setAmount(item.statistics.amount_avg_recently,'USDT')"></span></td>
                            </tr>
                            <tr>
                              <th>平均（全期間）</th>
                              <td><span v-text="setAmount(item.statistics.amount_avg,'USDT')"></span></td>
                            </tr>
                            <tr>
                              <th>中央値（直近１月）</th>
                              <td><span v-text="setAmount(item.statistics.amount_median_recently,'USDT')"></span></td>
                            </tr>
                            <tr>
                              <th>中央値（全期間）</th>
                              <td><span v-text="setAmount(item.statistics.amount_median,'USDT')"></span></td>
                            </tr>
                            <tr>
                              <th>MV取引</th>
                              <td><span
                                  v-text="item.statistics.mv_count+' 回（計 '+setAmount(item.statistics.mv_amount,'MV')+'）'"></span>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <button class="button has-width-full" v-on:click="showTradePage()">履歴詳細確認（※幅的にPC向け）</button>
                              </td>
                            </tr>
                            </tbody>
                          </table>

                        </div>

                      </div>

                    </div>

                  </div>

                  <div class="item-detail-right is-flex is-vertical">

                    <div class="is-flex is-liner has-pad-r20">

                      <div class="card has-width-full">
                        <div class="card-content">
                          <div class="content">
                            <lineGraph
                                :graph-data="setLineGraphData(item.graph.scale_volume,item.graph.volume,'取引件数',colors.volumeLineColor)">
                            </lineGraph>
                          </div>
                        </div>
                      </div>


                    </div>

                    <div class="is-flex is-liner has-pad-t20 has-pad-r20">

                      <div class="card has-width-full">
                        <div class="card-content">
                          <div class="content">
                            <lineGraph
                                :graph-data="setLineGraphData(item.graph.scale_amount,item.graph.amount,'USDT（同日内取引は平均額）',colors.amountLineColor)">
                            </lineGraph>
                          </div>
                        </div>
                      </div>


                    </div>

                    <div v-if="parseInt(item.parts_type,10)===256" class="is-flex is-liner has-pad-t20 has-pad-r20">（※注）指輪の初期値は運営公開情報による最大値です</div>

                    <div class="table-rap has-pad-t20 has-pad-r20">
                      <table class="table is-bordered status-detail is-narrow">
                        <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>HP</th>
                          <th>MP</th>
                          <th>物攻</th>
                          <th>防御</th>
                          <th>魔攻</th>
                          <th>攻速</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th>初期値</th>
                          <td><span v-text="item.status.hp"></span></td>
                          <td><span v-text="item.status.mp"></span></td>
                          <td><span v-text="item.status.attack"></span></td>
                          <td><span v-text="item.status.defense"></span></td>
                          <td><span v-text="item.status.magic_attack"></span></td>
                          <td><span v-text="item.status.atk_spd"></span></td>
                        </tr>
                        <tr>
                          <th>市場最高</th>
                          <td><span v-text="item.status_max.hp"></span></td>
                          <td><span v-text="item.status_max.mp"></span></td>
                          <td><span v-text="item.status_max.attack"></span></td>
                          <td><span v-text="item.status_max.defense"></span></td>
                          <td><span v-text="item.status_max.magic_attack"></span></td>
                          <td><span v-text="item.status_max.atk_spd"></span></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="table-rap has-pad-t20 has-pad-r20">
                      <table class="table is-bordered status-detail is-narrow">
                        <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>腕力</th>
                          <th>体力</th>
                          <th>速さ</th>
                          <th>知力</th>
                          <th>器用</th>
                          <th>精神</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th>初期値</th>
                          <td><span v-text="item.status.str"></span></td>
                          <td><span v-text="item.status.vit"></span></td>
                          <td><span v-text="item.status.agi"></span></td>
                          <td><span v-text="item.status.inte"></span></td>
                          <td><span v-text="item.status.dex"></span></td>
                          <td><span v-text="item.status.mnd"></span></td>
                        </tr>
                        <tr>
                          <th>市場最高</th>
                          <td><span v-text="item.status_max.str"></span></td>
                          <td><span v-text="item.status_max.vit"></span></td>
                          <td><span v-text="item.status_max.agi"></span></td>
                          <td><span v-text="item.status_max.inte"></span></td>
                          <td><span v-text="item.status_max.dex"></span></td>
                          <td><span v-text="item.status_max.mnd"></span></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>


                    <div class="table-rap has-pad-t20 has-pad-r20">
                      <table class="table is-bordered status-detail is-narrow">
                        <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>物理<br>ｸﾘ値</th>
                          <th>物理<br>ｸﾘ率</th>
                          <th>魔法<br>ｸﾘ値</th>
                          <th>魔法<br>ｸﾘ率</th>
                          <th>詠唱<br>速度</th>
                          <th>防御<br>効率</th>
                          <th>ｶﾞｰﾄﾞ<br>値</th>
                          <th>ｶﾞｰﾄﾞ<br>効果</th>
                          <th>ドロ<br>率</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th>市場最高</th>
                          <td><span v-text="item.status_max.physical_cri"></span></td>
                          <td><span v-text="item.status_max.physical_cri_multi"></span></td>
                          <td><span v-text="item.status_max.magic_cri"></span></td>
                          <td><span v-text="item.status_max.magic_cri_multi"></span></td>
                          <td><span v-text="item.status_max.cast_spd"></span></td>
                          <td><span v-text="item.status_max.def_proficiency"></span></td>
                          <td><span v-text="item.status_max.guard"></span></td>
                          <td><span v-text="item.status_max.guard_effect"></span></td>
                          <td><span v-text="item.status_max.item_drop_rate"></span></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>


                    <div class="table-rap has-pad-t20 has-pad-r20">
                      <table class="table is-bordered status-detail is-narrow">
                        <thead>
                        <tr>
                          <th rowspan="2">&nbsp;</th>
                          <th colspan="8">耐性</th>
                        </tr>
                        <tr>
                          <th>物</th>
                          <th>魔</th>
                          <th>火</th>
                          <th>風</th>
                          <th>水</th>
                          <th>地</th>
                          <th>光</th>
                          <th>闇</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th>市場最高</th>
                          <td><span v-text="item.status_max.physical_resist"></span></td>
                          <td><span v-text="item.status_max.magic_resist"></span></td>
                          <td><span v-text="item.status_max.fire_resist"></span></td>
                          <td><span v-text="item.status_max.wind_resist"></span></td>
                          <td><span v-text="item.status_max.water_resist"></span></td>
                          <td><span v-text="item.status_max.earth_resist"></span></td>
                          <td><span v-text="item.status_max.holy_resist"></span></td>
                          <td><span v-text="item.status_max.dark_resist"></span></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="table-rap has-pad-t20 has-pad-r20">
                      <table class="table is-bordered status-detail is-narrow">
                        <thead>
                        <tr>
                          <th rowspan="2">&nbsp;</th>
                          <th colspan="8">耐性</th>
                        </tr>
                        <tr>

                          <th>クリ</th>
                          <th>眠り</th>
                          <th>麻痺</th>
                          <th>毒</th>
                          <th>沈黙</th>
                          <th>移動不能</th>
                          <th>移動速度</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th>市場最高</th>
                          <td><span v-text="item.status_max.critical_resist"></span></td>
                          <td><span v-text="item.status_max.sleep_resist"></span></td>
                          <td><span v-text="item.status_max.stun_resist"></span></td>
                          <td><span v-text="item.status_max.poison_resist"></span></td>
                          <td><span v-text="item.status_max.silence_resist"></span></td>
                          <td><span v-text="item.status_max.root_resist"></span></td>
                          <td><span v-text="item.status_max.snare_resist"></span></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>


                </div>

                <div id="amano-images" class="has-width-full has-pad-t20" v-if="item.amano===true">
                  <h4 class="heading-h4">天野図鑑</h4>

                  <div class="is_flex is-liner has-flex-rap has-pad-t20">
                    <template v-for="(logo,logo_index) in item.setup_logo" :key="logo_index">

                      <img class="img-frame has-link has-margin-r5" v-bind:src="logo" alt=""
                           loading="lazy"
                           v-on:click="showModal(logo_index)">

                    </template>

                  </div>

                </div>

              </div>

            </div>

          </div>


        </div>


      </div>


    </div>

    <div class="modal nt-modal msg-dialog" :class="{'is-active': status.modal}" @keyup.esc="closeModal()">
      <div class="modal-background mtcms-modal-background" @click="closeModal()"></div>
      <div class="modal-card">
        <section class="modal-card-body" style="padding:0;">
          <slot name="body">
            <div class="amanoImage has-pad-20 is-flex is-liner">

              <div class="fix" style="flex-basis:50%;min-width:50%;">
                <img class="img-frame" style="width:240px;height:auto;" v-bind:src="item.setup_logo[modalImgIndex]"
                     alt="">
              </div>
              <div id="amanoSlideButtons" class="fix is-flex is-liner f_vcenter f_center"
                   style="flex-basis:50%;min-width:50%;">
                <div class="buttons has-addons selectGroupButtons">
                  <button class="button" v-bind:disabled="canBack===false" v-on:click="moveAmanoImg('back')">
                    <fa icon="fa-angle-left" class="fa-2xl"/>
                  </button>
                  <button class="button" v-bind:disabled="canNext===false" v-on:click="moveAmanoImg('next')">
                    <fa icon="fa-angle-right" class="fa-2xl"/>
                  </button>

                </div>

              </div>


            </div>
          </slot>
        </section>
      </div>
    </div>


  </div>
</template>

<script>
import {commonFunc} from '@/models/commonFunc'
//import navbar from "./common/NaviHeader.vue";
import axios from "axios";
import {Detail} from "@/models/detail";

import lineGraph from './common/LineGraph.vue'

import * as _ from "lodash";

export default {
  components: {
//    'navbar': navbar,
    'lineGraph': lineGraph,
  },
  name: 'item',
  data() {
    return {
      mode: 'base', //base, cosplay
      id: 0,
      item: new Detail(),
      colors: {
        volumeLineColor: '#40a650',
        amountLineColor: '#b74747'
      },
      modalImgIndex: 0,
      status: {
        loadData: false,
        modal: false
      }
    }
  },
  computed: {
    canBack: function () {
      return this.item.amano === true && this.item.setup_logo.length > 0 && this.modalImgIndex > 0;
    },
    canNext: function () {
      return this.item.amano === true && this.item.setup_logo.length > 0 && (this.modalImgIndex < (this.item.setup_logo.length - 1));
    }
  },
  created() {
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = parseInt(this.$route.params.id, 10);
    }
    if (this.id > 0) {
      this.initData();
    }

  },
  watch: {},
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/
    showModal: function (index) {
      if (this.item.amano === true) {
        this.modalImgIndex = index;
        this.status.modal = true;
      }
    },

    closeModal: function () {
      this.status.modal = false;
    },
    moveAmanoImg: function (type) {
      if (type === 'back' && this.canBack === true) {
        this.modalImgIndex--;
      }
      if (type === 'next' && this.canNext === true) {
        this.modalImgIndex++;
      }
    },
    showTradePage: function () {
      let resolvedRoute = this.$router.resolve({
        name: 'trade',
        params: {id: this.id}
      });
      window.open(resolvedRoute.href, 'tradeDetail' + this.id);
    },
    /*************************************************
     * 汎用処理
     *************************************************/
    setLineGraphData: function (scale, data, label, fill_color = '') {

      let viewData = {label: label, data: data, tension: 0.1, fill: true};
      if (fill_color) {
        viewData['borderColor'] = fill_color;
      }
      return {
        labels: scale,
        datasets: [viewData]
      };
    },


    /*************************************************
     * 算出
     *************************************************/
    getItemTypeName: function (item_type) {
      return commonFunc.getItemTypeName(item_type);
    },
    getPartsName: function (parts_type) {
      return commonFunc.getPartsName(parts_type);
    },
    getRankName: function (rarity) {
      return commonFunc.getRankName(rarity);
    },
    getJobEnable: function (value, jobs) {
      return ((value & jobs) === 0) ? '×' : '〇';
    },
    setTitle: function () {
      let title = this.item.title;
      if (!title || title === '') {
        title = '日本語名称不明（' + this.item.title_en + '）';
      } else {
        title += '（' + this.item.title_en + '）';
      }
      return title;
    },
    setAmount: function (amount, currency = '', dateStr = '') {
      let amount_str = amount.toLocaleString();
      if (currency !== '') {
        amount_str += ' ' + currency;
      }
      if (dateStr !== '') {
        amount_str += '（' + dateStr + '）';
      }
      return amount_str;
    },

    /*************************************************
     * 動的クラス設定
     *************************************************/


    /*************************************************
     * 初期処理
     *************************************************/


    initData: function () {
      return new Promise((resolve, reject) => {
        let self = this;
        axios.get('./json/dtl/' + this.id + '.json')
            .then(function (response) {
              if (response.data) {
                self.item = Detail.fromJson(response.data);
                self.status.loadData = true;
              }
              resolve();
            });
      });
    }


  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>
