<template>
  <div id="contents" class="content is-flex-auto-vertical is-liner">

    <div id="side" class="fix" style="flex-basis:60px;min-width:60px;">
      <navbar
          :config="config"
          @setAuth="setAuth"></navbar>
    </div>

    <div id="main-content" class="grow is-flex is-vertical">

      <div id="pageMenu" class="fix is-flex-auto-vertical is-liner f_left">

        <div class="navi-title is-flex is-liner f_left f_vcenter">
          <h1 class="grow site-title has-pad-l20">GK Market Analyzer</h1>
        </div>

        <button class="navi-box"
                v-on:click="setMode('recent')"
                v-bind:class="isActiveMode('recent')">直近７日
        </button>
        <button class="navi-box"
                v-on:click="setMode('short')"
                v-bind:class="isActiveMode('short')">１か月
        </button>
        <button class="navi-box"
                v-on:click="setMode('medium')"
                v-bind:class="isActiveMode('medium')">３か月
        </button>
        <button class="navi-box"
                v-on:click="setMode('all')"
                v-bind:class="isActiveMode('all')">全期間
        </button>
        <div class="spacer grow is-flex is-liner f_left f_vcenter"></div>
      </div>


      <div id="content" class="is-flex is-vertical">


        <h2>取引件数（アイテム単位）</h2>

        <div class="rank-list-with-thumbnail is-flex-auto-vertical is-liner has-flex-rap">

          <template v-for="(item,index) in group_volume.items" :key="item.item_id">

            <div class="card item-card">

              <div class="card-content">

                <div class="is-flex-auto-liner is-vertical">

                  <div class="card-image">
                    <img class="img-frame has-link" v-bind:src="item.img_logo" alt="item.title_en" loading="lazy" v-on:click="showDetailPage(item.item_id)">
                  </div>

                  <div class="content is-flex-auto-liner is-vertical f_center f_vcenter">
                    <p class="title volume" v-text="item.cnt.toLocaleString()"></p>
                    <div class="meta">
                      <p class="dt" v-text="item.dt_to"></p>
                    </div>
                  </div>

                </div>

                <div class="ribbon_rap">
                  <div class="ribbon-wrapper">
                    <span v-bind:class="setRibbonClass(index)" v-text="index+1"></span>
                  </div>
                </div>

              </div>
            </div>


          </template>

        </div>


        <h2>取引金額（アイテム単位/USDT）<span>※天野装備は固有画像の為、職・性別毎の卵サムネイルで代替</span></h2>

        <div class="rank-list-with-thumbnail is-flex is-liner has-flex-rap">

          <template v-for="(item,index) in group_amount.items" :key="item.item_id">

            <div class="card item-card">

              <div class="card-content">

                <div class="is-flex-auto-liner is-vertical">

                  <div class="card-image">
                    <img class="img-frame has-link" v-bind:src="item.img_logo" alt="item.title_en" loading="lazy" v-on:click="showDetailPage(item.item_id)">
                  </div>

                  <div class="content is-flex-auto-liner is-vertical f_center f_vcenter">
                    <p class="title amount" v-text="parseInt(item.total,10).toLocaleString()"></p>
                    <div class="meta">
                      <p class="dt" v-text="'（'+item.cnt.toLocaleString()+' 件）'"></p>
                      <p class="dt" v-text="item.dt_to"></p>
                    </div>
                  </div>

                </div>

                <div class="ribbon_rap">
                  <div class="ribbon-wrapper">
                    <span v-bind:class="setRibbonClass(index)" v-text="index+1"></span>
                  </div>
                </div>

              </div>
            </div>


          </template>

        </div>


        <h2>取引金額（単品/USDT）</h2>

        <div class="rank-list-with-thumbnail is-flex is-liner has-flex-rap">

          <template v-for="(item,index) in single_amount.items" :key="item.index">

            <div class="card item-card">

              <div class="card-content">

                <div class="is-flex-auto-liner is-vertical">

                  <div class="card-image">
                    <img class="img-frame has-link" v-bind:src="item.img_logo" alt="item.title_en" loading="lazy" v-on:click="showDetailPage(item.item_id)">
                  </div>

                  <div class="content is-flex-auto-liner is-vertical f_center f_vcenter">
                    <p class="title amount" v-text="parseInt(item.total,10).toLocaleString()"></p>
                    <div class="meta">
                      <p class="dt" v-text="item.dt"></p>
                    </div>
                  </div>

                </div>

                <div class="ribbon_rap">
                  <div class="ribbon-wrapper">
                    <span v-bind:class="setRibbonClass(index)" v-text="index+1"></span>
                  </div>
                </div>

              </div>
            </div>


          </template>

        </div>


      </div>

    </div>

  </div>
</template>

<script>
import navbar from "./common/NaviHeader.vue";
import axios from "axios";

import {Conf} from "@/models/conf";
import {RankSingles, RankSingle} from "@/models/rankSingle";
import {RankGroups, RankGroup} from "@/models/rankGroup";

export default {
  components: {
    'navbar': navbar,
  },
  name: 'ranking',
  props: {
    config: new Conf()
  },
  data() {
    return {
      mode: 'recent', //recent,short, medium, all
      jsonData: {
        single: {},
        group: {}
      },
      group_volume: new RankGroups(),
      group_amount: new RankGroups(),
      single_amount: new RankSingles()

    }
  },
  created() {
  },
  mounted() {
    this.firstLoad()
        .then(this.initData);
  },
  methods: {
    /*************************************************
     * 画面アクション
     *************************************************/
    setMode: function (mode) {
      this.mode = mode;
      this.initData();
    },
    showDetailPage:function(item_id){
      let resolvedRoute = this.$router.resolve({
        name: 'detail',
        params: {id: item_id}
      });
      window.open(resolvedRoute.href, 'itemDetail'+item_id);
    },

    /*************************************************
     * 動的クラス設定
     *************************************************/
    isActiveMode: function (mode) {
      let cname = {'is-active': false};

      if (this.mode === mode) {
        cname['is-active'] = true;
      }
      return cname;
    },
    setRibbonClass: function (index) {
      let cname = {
        'ribbon': false,
        'ribbon1': false,
        'ribbon2': false,
        'ribbon3': false,
      };

      if (index>2) {
        cname['ribbon'] = true;
      }else{
        cname['ribbon'+(index+1)] = true;
      }
      return cname;
    },

    /*************************************************
     * 初期処理
     *************************************************/
    initData: function () {
      return new Promise((resolve, reject) => {
        this.group_volume = new RankGroups();
        this.group_amount = new RankGroups();
        this.single_amount = new RankSingles();
        if (this.jsonData) {
          let data = this.jsonData;
          if (data.single && data.single[this.mode]) {
            this.single_amount = RankSingles.fromJson(data.single[this.mode]);
          }
          if (data.group && data.group[this.mode]) {
            this.group_amount = RankGroups.fromJson(data.group[this.mode].amount);
            this.group_volume = RankGroups.fromJson(data.group[this.mode].volume);
          }

        }
        resolve();
      });

    },
    firstLoad: function () {
      return new Promise((resolve, reject) => {
        let self = this;
        axios.get('./json/ranking.json')
            .then(function (response) {
              if (response.data) {
                self.jsonData = response.data;
              }
              resolve();
            });
      });
    },

    /*************************************************
     * コンポーネント連携
     *************************************************/
    setAuth: function (auth) {
      this.$emit('setAuth',auth);
    }

  }
}
</script>
<style scoped lang="scss">
* {
  box-sizing: border-box;
}
</style>
